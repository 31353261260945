/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i3 from "primeng/components/toast/toast";
import * as i4 from "primeng/components/common/messageservice";
import * as i5 from "../../node_modules/primeng/components/confirmdialog/confirmdialog.ngfactory";
import * as i6 from "primeng/components/confirmdialog/confirmdialog";
import * as i7 from "primeng/components/common/confirmationservice";
import * as i8 from "@angular/router";
import * as i9 from "./app.component";
import * as i10 from "./helio-core-services/services/app-config.service";
import * as i11 from "./helio-core-services/services/toast-display.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p-toast", [], null, null, null, i2.View_Toast_0, i2.RenderType_Toast)), i1.ɵdid(1, 1294336, null, 1, i3.Toast, [i4.MessageService], { style: [0, "style"], position: [1, "position"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵpod(3, { marginTop: 0 }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p-confirmDialog", [], null, null, null, i5.View_ConfirmDialog_0, i5.RenderType_ConfirmDialog)), i1.ɵdid(6, 180224, null, 1, i6.ConfirmDialog, [i1.ElementRef, i1.Renderer2, i7.ConfirmationService, i1.NgZone], null, null), i1.ɵqud(603979776, 2, { footer: 0 }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, "80px"); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.toastPosition, ""); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 10, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-app", [], [[1, "app-version", 0]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i9.AppComponent, [i10.AppConfigService, i1.NgZone, i11.ToastDisplayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).appVersion; _ck(_v, 0, 0, currVal_0); }); }
var AppComponentNgFactory = i1.ɵccf("he-app", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
