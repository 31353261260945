import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ConfirmDialogDisplayService } from '../../../helio-core-services';

@Component({
	selector: 'he-change-status-dialog',
	styleUrls: ['./change-status-dialog.scss'],
	templateUrl: './change-status-dialog.component.html'
})

export class ChangeStatusDialogComponent implements OnInit, OnChanges {
	@Input() isVisible = false;
	@Input() options: any;
	@Input() data: any;
	@Input() isDisabled = false;
	@Output() dialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();
	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private confirmationDisplayService: ConfirmDialogDisplayService
	) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
			status: new FormControl('', [
				Validators.required
			])
		});

		this.form.get('status').valueChanges
			.subscribe(newValue => {
				this.options.selectedStatus = newValue;
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		const change = changes['isVisible'];
		if (change !== undefined) {
			if (!change.firstChange && change.currentValue === true) {
				this.form.get('status').setValue(this.options.selectedStatus);
			}
		}
	}

	public closeDialog() {
		this.form.reset();
		this.dialogClose.emit(false);
	}

	buttonAction(button) {
		// confirm dialog
		this.confirmationDisplayService.showConfirmDialog({
			message: 'Are you sure that you want to proceed?',
			accept: () => {
				button.action();
			},
			reject: () => {
				return;
			}
		});
	}
}
