import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { map, delay, catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceHeadersConfig } from '../../shared/utilities/service-utilities/service-headers.config';
/**
 * Service class used to handle HTTP requests related to Login
 */
var LoginService = /** @class */ (function (_super) {
    tslib_1.__extends(LoginService, _super);
    /**
     * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
     * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
     */
    function LoginService(http, appConfigService, windowEventsService, router) {
        var _this = _super.call(this, http, ServiceController.LOGIN_CONTROLLER, appConfigService.authBaseURL, new ServiceHeadersConfig(true, 'application/x-www-form-urlencoded')) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        _this.windowEventsService = windowEventsService;
        _this.router = router;
        _this.isUserActive = false;
        _this.isUserActiveHandler();
        return _this;
    }
    Object.defineProperty(LoginService.prototype, "isLoggedIn", {
        get: function () {
            if (localStorage.getItem('access_token') !== null) {
                var now = new Date();
                this.tokenExpiresDate = new Date(localStorage.getItem('token_expires_date'));
                if (now < this.tokenExpiresDate) {
                    localStorage.setItem('log_status', 'true');
                    return true;
                }
            }
            localStorage.setItem('log_status', 'false');
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginService.prototype, "isInactivityDateLimitExpired", {
        get: function () {
            var now = new Date();
            var inactiveDateLimit = new Date(localStorage.getItem('inactivity_date_limit'));
            return (now >= inactiveDateLimit);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginService.prototype, "canRefreshToken", {
        get: function () {
            var refreshToken = localStorage.getItem('refresh_token');
            if (refreshToken !== null) {
                var now = new Date();
                var refreshTokenExpires = new Date(localStorage.getItem('refresh_token_expires_date'));
                return (now < refreshTokenExpires);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    LoginService.prototype.loginUser = function (loginData) {
        var _this = this;
        loginData.client_id = this.appConfigService.tokenClientID;
        return this.post(undefined, loginData)
            .pipe(map(function (res) {
            return _this.saveSessionData(res);
        }));
    };
    LoginService.prototype.refreshUserToken = function (autoLogout) {
        var _this = this;
        if (autoLogout === void 0) { autoLogout = true; }
        var data = {
            refresh_token: localStorage.getItem('refresh_token'),
            grant_type: 'refresh_token',
            client_id: this.appConfigService.tokenClientID
        };
        return this.post(undefined, data)
            .pipe(map(function (res) {
            return _this.saveSessionData(res);
        }), catchError(function () {
            if (autoLogout) {
                _this.logoutAndRedirect(true);
            }
            return of(null);
        }));
    };
    /**
     * Not used in this service
     */
    LoginService.prototype.mapData = function (data) {
        // not needed in this service
    };
    /**
     * Clears data stored locally to log out
     */
    LoginService.prototype.logOut = function () {
        // Stop refresh of token
        this.unsubscribeSessionExpiredHandler();
        // Clean up local storage
        localStorage.setItem('log_status', 'false');
        localStorage.removeItem('access_token');
        localStorage.removeItem('token_type');
        localStorage.removeItem('access_rights');
        localStorage.removeItem('logged_in_userID');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_expires_date');
        localStorage.removeItem('refresh_token_expires_date');
        localStorage.removeItem('inactivity_date_limit');
        localStorage.removeItem('requires_pass_change');
    };
    /**
     * Function to save the user data in the localStorage after a successful login
     */
    LoginService.prototype.saveSessionData = function (data) {
        localStorage.setItem('log_status', 'true');
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('token_type', data.token_type);
        localStorage.setItem('expires_in', data.expires_in);
        localStorage.setItem('refresh_token', data.refresh_token);
        var tokenExpires = new Date();
        tokenExpires.setSeconds(tokenExpires.getSeconds() + data.expires_in);
        localStorage.setItem('token_expires_date', tokenExpires.toISOString());
        this.tokenExpiresDate = new Date(tokenExpires.toISOString());
        var refreshTokenExpires = new Date(data['.expires']);
        localStorage.setItem('refresh_token_expires_date', refreshTokenExpires.toISOString());
        // Get the access rights from token
        var encodedString = data.access_token.split('.')[1];
        // Decode it (base64)...
        var decodedString = atob(encodedString);
        // ...and store them locally
        localStorage.setItem('access_rights', decodedString);
        localStorage.setItem('logged_in_userID', JSON.parse(decodedString).Id);
        localStorage.setItem('requires_pass_change', JSON.parse(decodedString).RequiresPasswordChange);
    };
    LoginService.prototype.logoutAndRedirect = function (isSessionExpired) {
        if (isSessionExpired === void 0) { isSessionExpired = false; }
        // get url without query params
        // ref: https://stackoverflow.com/a/46107850
        var urlTree = this.router.parseUrl(this.router.url);
        var key = 'primary';
        var rootChildren = urlTree.root.children[key];
        var urlWithoutParams;
        if (rootChildren !== undefined) {
            urlWithoutParams = urlTree.root.children[key].segments.map(function (it) { return it.path; }).join('/');
        }
        if (urlWithoutParams !== 'login') {
            var queryParams = (isSessionExpired) ? { queryParams: { 'sessionExpired': true, 'redirectURL': this.router.url } } : undefined;
            this.logOut();
            this.router.navigate(['/login'], queryParams);
        }
    };
    LoginService.prototype.sessionExpiredHandler = function () {
        var _this = this;
        return of(true)
            .pipe(delay(this.tokenExpiresDate), map(function () {
            if (_this.isUserActive) {
                _this.refreshUserToken().subscribe(function () {
                    _this.sessionExpiredHandlerSubscription = _this.sessionExpiredHandler().subscribe();
                });
            }
        }));
    };
    LoginService.prototype.isUserActiveHandler = function () {
        var _this = this;
        this.windowEventsService.onFocus.subscribe(function () {
            _this.isUserActive = true;
            localStorage.setItem('is_user_active', 'true');
            // if token is valid (i.e. not expired)
            if (_this.isLoggedIn) {
                if (_this.sessionExpiredHandlerSubscription === undefined) {
                    _this.sessionExpiredHandlerSubscription = _this.sessionExpiredHandler().subscribe();
                }
            }
            else {
                if (!_this.isInactivityDateLimitExpired) {
                    if (_this.canRefreshToken) {
                        _this.refreshUserToken().subscribe(function () {
                            _this.unsubscribeSessionExpiredHandler();
                            _this.sessionExpiredHandlerSubscription = _this.sessionExpiredHandler().subscribe();
                        });
                    }
                    else {
                        _this.logoutAndRedirect(true);
                    }
                }
                else {
                    _this.logoutAndRedirect(true);
                }
            }
        });
        this.windowEventsService.onBlur.subscribe(function () {
            _this.isUserActive = false;
            localStorage.setItem('is_user_active', 'false');
            _this.unsubscribeSessionExpiredHandler();
            if (_this.isLoggedIn) {
                var now = new Date();
                var inactiveDateLimit = new Date(now.setSeconds(now.getSeconds() + _this.appConfigService.inactiveTimeLimit));
                localStorage.setItem('inactivity_date_limit', inactiveDateLimit.toISOString());
            }
        });
    };
    LoginService.prototype.unsubscribeSessionExpiredHandler = function () {
        if (this.sessionExpiredHandlerSubscription !== undefined) {
            this.sessionExpiredHandlerSubscription.unsubscribe();
        }
    };
    return LoginService;
}(BaseService));
export { LoginService };
