<div class="search-field ui-g ui-lg-12 ui-xl-10">
	<div class="ui-g-2">
		<p-selectButton [disabled]="isFirstSearchField" [options]="searchAndOr" [(ngModel)]="selectedSearchAndOr"></p-selectButton>
	</div>

	<div class="ui-g-8">
		<div class="ui-g-2 ui-lg-3 search-field-name">
			<label>{{ searchField.label }}</label>
		</div>

		<div class="ui-g-5 ui-lg-4">
			<span class="ui-float-label">
				<p-dropdown [inputId]="operatorInputID" [options]="dropdownSearchFieldOperators" [ngModel]="selectedOperator" (ngModelChange)="selectSearchOperator($event)"></p-dropdown>
				<label for="{{ operatorInputID }}">Operator</label>
			</span>
		</div>

		<div [ngSwitch]="searchField.type" class="search-field-value ui-g-5 ui-lg-4">
			<!-- String -->
			<span class="ui-float-label" *ngSwitchCase="searchFieldType.String">
				<input id="{{ inputID }}" pInputText class="string-value"
					[ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)" />

				<label for="{{ inputID }}">Value - Text</label>
			</span>

			<!-- Boolean -->
			<span class="ui-float-label" *ngSwitchCase="searchFieldType.Boolean">
				<p-dropdown [inputId]="inputID" [options]="booleanOptions" [autoDisplayFirst]="false"
					[ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)">
				</p-dropdown>
				<label for="{{ inputID }}">Value - True/False</label>
			</span>

			<!-- Date -->
			<ng-container *ngSwitchCase="searchFieldType.Date">
				<span class="ui-float-label" *ngIf="selectedOperator !== searchOperatorType.BETWEEN">
					<p-calendar class="date-value" type="text" [inputId]="inputID" [utc]="true" [monthNavigator]="true" [yearNavigator]="true" [showTime]="true" yearRange="2000:2030"
						[ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"></p-calendar>

					<label for="{{ inputID }}">Value - Date</label>
				</span>

				<span class="ui-float-label" *ngIf="selectedOperator === searchOperatorType.BETWEEN">
					<p-calendar class="date-value" type="text" [inputId]="input2ID" [utc]="true" selectionMode="range" [monthNavigator]="true" [yearNavigator]="true" [showTime]="true" yearRange="2000:2030"
						[ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"></p-calendar>

					<label for="{{ input2ID }}">Value - Date Range</label>
				</span>
			</ng-container>

			<!-- Number -->
			<ng-container *ngSwitchCase="searchFieldType.Number">
				<span class="ui-float-label" *ngIf="selectedOperator !== searchOperatorType.BETWEEN">
					<p-spinner class="number-value" size="30" [inputId]="inputID" [ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"></p-spinner>
					<label for="{{ inputID }}">Value - Number</label>
				</span>

				<div *ngIf="selectedOperator === searchOperatorType.BETWEEN" class="number-range-wrapper">
					<span class="number-range-value">
						<span class="min">{{ numberRangeMinValue }}</span>
						<span class="max">{{ numberRangeMaxValue }}</span>
					</span>
					<p-slider [min]="searchField.minNumberRange" [max]="searchField.maxNumberRange" [range]="true" [style]="{'width': '100%'}"
						[ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"></p-slider>
				</div>
			</ng-container>

			<!-- List -->
			<p-multiSelect *ngSwitchCase="searchFieldType.List" [options]="searchField.listOptions" [resetFilterOnHide]="true" [style]="{'width': '100%'}"
				[ngModel]="searchField.searchValue" (ngModelChange)="updateFilterString($event, searchField)"></p-multiSelect>
		</div>
	</div>
	<div class="ui-g-2">
		<button class="helio-orange-btn" pButton (click)="delete()" icon="ui-icon-delete" title="Delete Search Field"></button>
	</div>
</div>
