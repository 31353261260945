import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'heCountdownTo'
})

export class CountdownToPipe implements PipeTransform {
	transform(toTime: Date, now: Date, countdownOverText?: string): string {
		if (toTime !== null && now !== undefined) {
			const nowTime = new Date(now).getTime();
			const countdownToTime = new Date(toTime).getTime();

			const diff = countdownToTime - nowTime;

			if (diff <= 0 && countdownOverText) {
				return countdownOverText;
			}

			return this.getDateTime(diff);
		}
	}

	private getDateTime(time: number): string {
		const timeInSeconds: number = Math.floor((time / 1000) % 60);
		const timeInMinutes: number = Math.floor(time / (1000 * 60) % 60);
		const timeInHours: number = Math.floor(time / (1000 * 60 * 60) % 24);
		const timeInDays: number = Math.floor(time / (1000 * 60 * 60 * 24));

		if (timeInDays < 1) {
			if (timeInHours < 1) {
				if (timeInMinutes < 1) {
					return (timeInSeconds === 1) ? `${timeInSeconds} sec` : `${timeInSeconds} sec`;
				}

				return (timeInMinutes === 1) ? `${(timeInMinutes + 1)} min` : `${(timeInMinutes + 1)} min`;
			} else {
				return (timeInHours === 1)
					? (timeInMinutes === 0 ? `${timeInHours} hour` : `${timeInHours} hour ${timeInMinutes} min`)
					: (timeInMinutes === 0 ? `${timeInHours} hours` : `${timeInHours} hours ${timeInMinutes} min`);
			}
		}
		return (timeInDays === 1) ? `${timeInDays} day` : `${timeInDays} days`;
	}
}
