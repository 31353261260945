import { AppConfig } from '../../shared/models/general/app-config.model';
var AppConfigService = /** @class */ (function () {
    function AppConfigService(http) {
        this.http = http;
    }
    AppConfigService.prototype.load = function (url) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.get(url)
                .subscribe(function (config) {
                _this.setAppConfigObj(config);
                localStorage.setItem('helioEngineAppConfig', JSON.stringify(_this.config));
                resolve();
            });
        });
    };
    Object.defineProperty(AppConfigService.prototype, "serviceBaseURL", {
        get: function () {
            return (this.isCentralServer) ? this.appConfig.centralBaseURL : this.appConfig.tenantBaseURL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "authBaseURL", {
        get: function () {
            return (this.isCentralServer) ? this.appConfig.centralAuthURL : this.appConfig.tenantAuthURL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "walletBaseURL", {
        get: function () {
            return this.appConfig.walletBaseURL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "isCentralServer", {
        get: function () {
            return this.config.isCentralServer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "hasWallet", {
        get: function () {
            return this.config.hasWallet;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "useSubdomain", {
        get: function () {
            return this.config.useSubdomain;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "genericErrorMessage", {
        get: function () {
            return this.config.genericErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "tableEmptyMessage", {
        get: function () {
            return this.config.tableEmptyMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "tableMissingDataMessage", {
        get: function () {
            return this.config.tableMissingDataMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "defaultDateFormat", {
        get: function () {
            return this.config.defaultDateFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "defaultTimezoneOffset", {
        get: function () {
            return this.config.defaultTimezoneOffset;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "defaultNumberFormat", {
        get: function () {
            return this.config.defaultNumberFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "defaultMoneyFormat", {
        get: function () {
            return this.config.defaultMoneyFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "tokenClientID", {
        get: function () {
            return this.config.tokenClientID;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "inactiveTimeLimit", {
        get: function () {
            return this.config.inactiveTimeLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigService.prototype, "appConfig", {
        get: function () {
            return this.config;
        },
        enumerable: true,
        configurable: true
    });
    AppConfigService.prototype.setAppConfigObj = function (config) {
        this.config = new AppConfig(config.isCentralServer, config.hasWallet, config.useSubdomain, config.centralBaseURL, config.centralAuthURL, config.tenantBaseURL, config.tenantAuthURL, config.walletBaseURL, config.genericErrorMessage, config.tableEmptyMessage, config.tableMissingDataMessage, config.defaultDateFormat, config.defaultTimezoneOffset, config.defaultNumberFormat, config.defaultMoneyFormat, config.tokenClientID, config.inactiveTimeLimit);
    };
    return AppConfigService;
}());
export { AppConfigService };
