/**
 * Utility class to set request body
 */
export class ServiceRequestBodyUtility {
	/**
	 * Function to set the request body to the correct format based on the Content-Type
	 * @param body Object to be passed via the HTTP request
	 * @param contentType Content-Type of the request
	 *
	 * Supported types: 'application/json' and 'application/x-www-form-urlencoded'
	 * @returns The formatted data
	 */
	public static getRequestBody(body: any, contentType: string): any {
		switch (contentType) {
			case 'application/json':
				return JSON.stringify(body);

			case 'application/x-www-form-urlencoded':
				let urlencoded = '';
				let isFirst = true;

				for (const key in body) {
					if (body.hasOwnProperty(key)) {
						urlencoded += (!isFirst) ? '&' : '';
						urlencoded += key + '=' + encodeURIComponent(body[key]);
						isFirst = false;
					}
				}
				return urlencoded;

			default:
				throw new Error('Invalid Content Type parameter');
		}
	}
}
