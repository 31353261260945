<div id="login-container" class="ui-g-4 ui-g-offset-4">
	<header id="main">
		<he-app-title></he-app-title>
	</header>
	<div class="card">
		<header id="secondary">
			<span *ngIf="!isSessionExpired">Log in to Helio Engine</span>
			<span *ngIf="isSessionExpired">Your session has expired, please log in again</span>
		</header>

		<form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
			<div class="login-form-content ui-grid ui-grid-responsive ui-fluid">
				<div class="ui-grid-row">
					<span class="ui-float-label ui-g-12">
						<input formControlName="username" pInputText class="float-input" autocomplete="username" type="text" id="username" name="username">
						<label class="float-input">Username</label>
					</span>
				</div>
				<div class="ui-grid-row">
					<span class="ui-float-label ui-g-12">
						<input heTogglePasswordVisibility formControlName="password" pInputText class="float-input" type="password" autocomplete="current-password" id="password" name="password">
						<label class="float-input">Password</label>
					</span>
				</div>
			</div>
			<div class="login-bottom ui-grid-row add-padding">
				<span class="pull-right">
					<button class="helio-orange-btn" type="submit" icon="ui-icon-person" pButton [disabled]="!loginForm.valid" label="Login"></button>
				</span>
				<ng-container *ngIf="isLoginFormLoading">
					<p-progressSpinner class="pull-right" [style]="progressSpinnerStyle" strokeWidth="5"></p-progressSpinner>
				</ng-container>
			</div>
		</form>
	</div>
	<p *ngIf="loginFailed" id="login-error" class="alert alert-danger" role="alert" style="margin-top: 20px;">
		<span>Invalid Login Details</span>
	</p>
	<p [hidden]="isLoginValid" id="error" class="alert alert-danger" role="alert" style="margin-top: 20px;">
		<span>Both Username and Password are required</span>
	</p>
</div>
