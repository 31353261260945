/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/components/header/header.component.ngfactory";
import * as i3 from "../shared/components/header/header.component";
import * as i4 from "../change-password/change-password.component.ngfactory";
import * as i5 from "../change-password/change-password.component";
import * as i6 from "@angular/forms";
import * as i7 from "../helio-core-services/services/app-loader.service";
import * as i8 from "../helio-core-services/services/user-rights.service";
import * as i9 from "../helio-core-services/services/login.service";
import * as i10 from "../change-password/change-password.service";
import * as i11 from "../helio-core-services/services/bo-error-handler.service";
import * as i12 from "@angular/router";
import * as i13 from "./my-profile.component";
import * as i14 from "../helio-core-services/services/breadcrumb.service";
var styles_MyProfileComponent = [i0.styles];
var RenderType_MyProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyProfileComponent, data: {} });
export { RenderType_MyProfileComponent as RenderType_MyProfileComponent };
export function View_MyProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-header", [["headerTitle", "My Profile"]], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderComponent, [], { headerTitle: [0, "headerTitle"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "he-change-password", [], null, null, null, i4.View_ChangePasswordComponent_0, i4.RenderType_ChangePasswordComponent)), i1.ɵdid(4, 114688, null, 0, i5.ChangePasswordComponent, [i6.FormBuilder, i7.AppLoaderService, i8.UsersService, i9.LoginService, i10.ChangePasswordService, i11.BoErrorHandlerService, i12.Router], { isModal: [0, "isModal"], headerMessage: [1, "headerMessage"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "My Profile"; _ck(_v, 1, 0, currVal_0); var currVal_1 = false; var currVal_2 = "Change your password"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_MyProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-my-profile", [], null, null, null, View_MyProfileComponent_0, RenderType_MyProfileComponent)), i1.ɵdid(1, 114688, null, 0, i13.MyProfileComponent, [i14.BreadcrumbService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyProfileComponentNgFactory = i1.ɵccf("he-my-profile", i13.MyProfileComponent, View_MyProfileComponent_Host_0, {}, {}, []);
export { MyProfileComponentNgFactory as MyProfileComponentNgFactory };
