import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from '../helio-core-services';
import { Login } from '../shared';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'he-login',
	styleUrls: [
		'./login.component.scss'
	],
	templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
	loginModel: Login = new Login();
	isLoginFormLoading = false;
	loginFailed = false;
	loginDetailsInvalid = false;
	checksum = '-1';

	isSessionExpired = false;

	progressSpinnerStyle: any = {
		width: '28px',
		height: '28px',
		position: 'relative',
		top: '10px',
		right: '10px'
	};

	loginForm: FormGroup;

	private redirectURL: string;

	constructor(
		private router: Router,
		private loginService: LoginService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder
	) { }

	ngOnInit() {
		this.formInit();

		this.route.queryParamMap.subscribe((params: ParamMap) => {
			if (params.has('sessionExpired')) {
				this.isSessionExpired = (params.get('sessionExpired') === 'true');
			}

			if (params.has('redirectURL')) {
				this.redirectURL = params.get('redirectURL');
			}
		});
	}

	submitLogin() {
		if (!this.isLoginFormLoading) {
			this.isLoginFormLoading = true;
			this.loginFailed = false;

			this.loginModel.username = this.loginForm.get('username').value;
			this.loginModel.password = this.loginForm.get('password').value;

			this.loginService.loginUser(this.loginModel)
				.subscribe(loginResponse => {
					if (this.loginService.isLoggedIn) {
						this.isLoginFormLoading = false;
						localStorage.setItem('user_name', this.loginModel.username);

						if (this.redirectURL !== undefined) {
							this.router.navigateByUrl(this.redirectURL);
						} else {
							this.router.navigateByUrl('/home');
						}
					}
				},
					error => {
						this.isLoginFormLoading = false;
						this.loginFailed = true;
						this.loginModel.password = '';
					});
		}
	}

	get isLoginValid(): boolean {
		const username = this.loginForm.get('username');
		const password = this.loginForm.get('password');

		return (username.valid || username.pristine) && (password.valid || password.pristine);
	}

	private formInit() {
		this.loginForm = this.formBuilder.group({
			username: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required)
		});
	}
}
