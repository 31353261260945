import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { BaseService } from './base.service';
import { ServiceController, ServiceAction } from '../../shared/utilities';
/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
var NomenclaturesService = /** @class */ (function (_super) {
    tslib_1.__extends(NomenclaturesService, _super);
    /**
     * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
     * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
     */
    function NomenclaturesService(http, appConfigService) {
        var _this = _super.call(this, http, (appConfigService.isCentralServer) ?
            ServiceController.NOMENCLATURES_CENTRAL_CONTROLLER : ServiceController.NOMENCLATURES_TENANT_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    NomenclaturesService.prototype.getCurrencies = function (value) {
        var _this = this;
        if (this.currencies === undefined) {
            return this.getCurrenciesFromServer(value).pipe(map(function (res) { _this.currencies = res; return _this.currencies; }));
        }
        else {
            return of(this.currencies);
        }
    };
    NomenclaturesService.prototype.getLanguages = function (value) {
        var _this = this;
        if (this.languages === undefined) {
            return this.getLanguagesFromServer(value).pipe(map(function (res) { _this.languages = res; return _this.languages; }));
        }
        else {
            return of(this.languages);
        }
    };
    NomenclaturesService.prototype.getCountries = function (value) {
        var _this = this;
        if (this.countries === undefined) {
            return this.getCountriesFromServer(value).pipe(map(function (res) { _this.countries = res; return _this.countries; }));
        }
        else {
            return of(this.countries);
        }
    };
    NomenclaturesService.prototype.getTimezones = function (value) {
        var _this = this;
        if (this.timezones === undefined) {
            return this.getTimezonesFromServer(value).pipe(map(function (res) { _this.timezones = res; return _this.timezones; }));
        }
        else {
            return of(this.timezones);
        }
    };
    NomenclaturesService.prototype.getServerNames = function (value) {
        var _this = this;
        if (this.serverNames === undefined) {
            return this.getServerNamesFromServer(value).pipe(map(function (res) { _this.serverNames = res; return _this.serverNames; }));
        }
        else {
            return of(this.serverNames);
        }
    };
    NomenclaturesService.prototype.getCurrenciesFromServer = function (value) {
        return this.get(ServiceAction.NOMENCLATURES_CURRENCY, value);
    };
    NomenclaturesService.prototype.getLanguagesFromServer = function (value) {
        return this.get(ServiceAction.NOMENCLATURES_LANGUAGES, value);
    };
    NomenclaturesService.prototype.getCountriesFromServer = function (value) {
        return this.get(ServiceAction.NOMENCLATURES_COUNTRIES, value);
    };
    NomenclaturesService.prototype.getTimezonesFromServer = function (value) {
        return this.get(ServiceAction.NOMENCLATURES_TIMEZONES, value);
    };
    NomenclaturesService.prototype.getServerNamesFromServer = function (value) {
        return this.get(ServiceAction.NOMENCLATURES_SERVERNAMES, value);
    };
    return NomenclaturesService;
}(BaseService));
export { NomenclaturesService };
