import {
	ContentChild, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild
} from '@angular/core';
import {HttpParams} from '@angular/common/http';

import {Menu} from 'primeng/menu';
import {LazyLoadEvent, MenuItem, SelectItem} from 'primeng/api';

import {SearchField} from './advanced-search/search-field.model';
import {
	CheckboxColumnPos
} from './shared/checkbox-column-pos.enum';
import {SubmitSearchEvent} from './advanced-search/submit-search-event.model';
import {ObjPropsStringDotNotationUtility} from '../../utilities';
import {AppConfigService} from '../../../helio-core-services/services/app-config.service';
import {PrimeNGColumnExtend} from './shared/primeng-col-extend.model';
import {DataTableAction} from './shared/data-table-action.model';
import {DataTableLazyLoadEvent} from './shared/data-table-lazy-load-event.model';
import {RowSelectChangeEvent} from './shared/row-select-change-event.model';
import {ColumnType} from './shared/column-type.enum';
import {AdvancedSearchService} from 'src/app/helio-core-services';

@Component({
	selector: 'he-data-table-v3',
	styleUrls: [
		'./data-table-v3.component.scss'
	],
	templateUrl: './data-table-v3.component.html'
})

export class DataTableV3Component implements OnInit {
	@Input() columns: PrimeNGColumnExtend[];
	@Input() dataKey: string;
	@Input() data: any[];
	@Input() paginator = true;
	@Input() lazy = false;
	@Input() totalRecords: number;
	@Input() selectionCheckbox: CheckboxColumnPos;
	@Input() tableActions: DataTableAction[];
	@Input() showHeader = true;
	@Input() expandableRows = false;
	@Input() selectedRows: any[] = [];
	@Input() resizableColumns = true;
	@Input() scrollable = false;
	@Input() tableMessage: string = this.appConfigService.tableEmptyMessage;
	@Input() searchFields: SearchField[];
	@Input() initialFilter: SubmitSearchEvent;
	@Input() parentData: any = undefined;
	@Input() editable = false;
	@Input() columnResizeMode: 'fit' | 'expand' = 'expand';
	@Input() canExportAll = false;
	@Input() showExportAll = false;

	@Output() lazyLoad: EventEmitter<DataTableLazyLoadEvent> = new EventEmitter<DataTableLazyLoadEvent>();
	@Output() rowSelectChange: EventEmitter<RowSelectChangeEvent> = new EventEmitter<RowSelectChangeEvent>();
	@Output() cellEditComplete: EventEmitter<any> = new EventEmitter<any>();
	@Output() selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() exportToCsvRequested: EventEmitter<any> = new EventEmitter<any>();

	// @ViewChild(DataTable, { static: false }) dataTable: DataTable;
	@ViewChild('menu', {static: false}) rowMenu: Menu;

	@ContentChild(TemplateRef, {static: false}) rowTemplate: TemplateRef<ElementRef>;

	columnType: typeof ColumnType = ColumnType;
	checkboxColumnPos: typeof CheckboxColumnPos = CheckboxColumnPos;
	tableActionsMenu: MenuItem[];
	bulkActionsMenu: MenuItem[];
	rowActionsMenu: MenuItem[];
	rowActionData: any[];
	isBulkMode = false;
	hasBulkActions = false;
	hasTableActions = false;
	showAdvancedSearch = false;

	rowsPerPage: SelectItem[] = [
		{label: '10', value: 10},
		{label: '25', value: 25},
		{label: '50', value: 50}
	];

	rowsToDisplay: number = this.rowsPerPage[0].value;
	firstRow = 0;
	rowColspan = 0;

	listForInlineEditColumnType: ColumnType = ColumnType.ListForInlineEdit;
	dateColumnType: ColumnType = ColumnType.Date;
	// dateFormat: string;
	// timezoneOffset: string;
	// numberFormat: string;
	// moneyFormat: string;

	private advancedSearchData: SubmitSearchEvent;
	private isAllDataSelected = false;
	private lastPrimeNGTableEvent: LazyLoadEvent;
	private urlPath = window.location.hash;

	constructor(private appConfigService: AppConfigService, private advancedSearchService: AdvancedSearchService) {
	}

	ngOnInit() {
		// this.dateFormat = this.appConfigService.defaultDateFormat;
		// this.timezoneOffset = this.appConfigService.defaultTimezoneOffset;
		// this.numberFormat = this.appConfigService.defaultNumberFormat;
		// this.moneyFormat = this.appConfigService.defaultMoneyFormat;
		this.showAdvancedSearch = this.advancedSearchService.shouldShowAdvancedSearch(this.urlPath);
		this.generateTableActions();
		this.setExpandedRowColspan();

		if ((this.hasBulkActions || this.expandableRows || this.selectionCheckbox !== undefined) && this.dataKey === undefined) {
			throw new Error('Data Table has Row Selection or Row Expand enabled. Please set a dataKey to uniquely identify each row.');
		}

		if (!this.lazy) {
			this.totalRecords = this.data.length;
			if (this.totalRecords === 0) {
				this.tableMessage = this.appConfigService.tableMissingDataMessage;
			}
		}

		this.advancedSearchData = this.initialFilter;
	}

	loadLazyDataTable(event?: LazyLoadEvent): void {
		this.lastPrimeNGTableEvent = event;

		const emitData: DataTableLazyLoadEvent = {
			primeNGEvent: event,
			take: (event !== undefined) ? event.rows : this.rowsToDisplay,
			offset: (event !== undefined) ? event.first : 0
		};

		if (event !== undefined) {
			if (event.sortField !== undefined) {
				const sortOrder = (event.sortOrder === 1) ? 'asc' : 'desc';
				const orderByStr = `${event.sortField} ${sortOrder}`;

				emitData.orderBy = orderByStr;
			}
		}

		if (this.advancedSearchData !== undefined) {
			emitData.advancedSearchData = this.advancedSearchData;
		}

		emitData.urlParams = this.getURLSearchParams(emitData);

		this.lazyLoad.emit(emitData);
	}

	submitAdvancedSearch(event?: SubmitSearchEvent): void {
		this.advancedSearchData = event;
		this.loadLazyDataTable();
	}

	clearAdvancedSearch(): void {
		this.advancedSearchData = undefined;
		this.loadLazyDataTable();
	}

	showAdvancedSearchClick(): void {
		this.showAdvancedSearch = !this.showAdvancedSearch;
	}

	closeAdvancedSearch(): void {
		this.showAdvancedSearch = false;
		this.advancedSearchService.fields.delete(this.urlPath);
		this.advancedSearchService.setShowAdvancedSearch(this.urlPath, false);
	}

	rowActionsClick(data: any): void {
		this.rowActionData = data;
	}

	bulkActionsClick(): void {
		this.isBulkMode = !this.isBulkMode;
		this.showExportAll = !this.showExportAll;

		if (!this.isBulkMode) {
			this.rowColspan--;

			// empty selected rows array
			this.selectedRows.splice(0);

			// slice array to trigger change in array
			this.selectedRows = this.selectedRows.slice();
		} else {
			this.rowColspan++;
		}
	}

	// TODO: Review once PrimeNG Turbo Table is implemented. (https://www.primefaces.org/primeng/#/table)
	// Current implementation is buggy.
	// Function resets data table to first page.
	// When table is set to lazy, call to server is not triggered when viewing first page and selecting a larger page size.
	rowsToDisplayChange(value: number): void {
		this.firstRow = 0;
		this.rowsToDisplay = value;

		if (this.lazy) {
			this.lastPrimeNGTableEvent.first = this.firstRow;
			this.lastPrimeNGTableEvent.rows = this.rowsToDisplay;

			this.loadLazyDataTable(this.lastPrimeNGTableEvent);
		} else {
			this.data = this.data.slice();
		}
	}

	// getTooltipString(data: any[]): string {
	// 	return data.join(', ');
	// }

	onRowSelectChange(event: any, isChecked?: boolean) {
		this.isAllDataSelected = (event.checked || this.selectedRows.length === this.totalRecords);
		this.selectedRowsChange.emit(this.selectedRows);

		this.rowSelectChange.emit({
			primeNGEvent: event,
			isSelectAll: this.isAllDataSelected,
			clickedRowData: (event.data) ? event.data : undefined,
			selectedRows: this.selectedRows,
			isChecked: (event.checked || isChecked)
		});
	}

	cellEditModelChange(value: any, data: any, field: string) {
		ObjPropsStringDotNotationUtility.setObjValue(data, field, value);
	}

	onCellEditComplete(rowData: any) {
		console.log('EDITING DONE');
		this.cellEditComplete.emit(rowData);
	}

	getKeyFilter(columnType: ColumnType) {
		switch (columnType) {
			case ColumnType.Number:
				return 'num';
			case ColumnType.Money:
				return 'money';
			case ColumnType.Default:
			case ColumnType.String:
			case ColumnType.Date:
			default:
				return 'alphanum';
		}
	}

	private setExpandedRowColspan() {
		this.rowColspan = this.columns.length;
		if (this.expandableRows) {
			this.rowColspan++;
		}

		if (this.selectionCheckbox !== undefined) {
			this.rowColspan++;
		}

		if (this.tableActions !== undefined) {
			if (this.tableActions.length > 0) {
				const rowActionsTot = this.tableActions.filter(tableAction => tableAction.isRowAction !== false).length;
				if (rowActionsTot > 0) {
					this.rowColspan++;
				}
			}
		}
	}

	private generateTableActions(): void {
		if (this.tableActions !== undefined) {

			this.tableActions.forEach((tempAction: DataTableAction) => {
				const actionMenuItem: MenuItem = Object.assign({}, tempAction.menuItem);

				if (tempAction.callback !== undefined) {
					actionMenuItem.command = (event) => {
						const data = (!this.isBulkMode || this.selectedRows.length === 0) ? [this.rowActionData] : this.selectedRows;
						const callbackObj = {
							data: data,
							isAllDataSelected: this.isAllDataSelected,
							parentData: this.parentData
						};

						tempAction.callback(callbackObj);
					};
				}

				if (tempAction.isRowAction === undefined || tempAction.isRowAction === true) {
					if (this.rowActionsMenu === undefined) {
						this.rowActionsMenu = [];
					}
					this.rowActionsMenu.push(actionMenuItem);
				} else if (tempAction.isBulkAction === undefined || tempAction.isBulkAction === true) {
					if (this.bulkActionsMenu === undefined) {
						this.bulkActionsMenu = [];
					}
					this.bulkActionsMenu.push(actionMenuItem);
				} else if (tempAction.isTableAction === true) {
					if (this.tableActionsMenu === undefined) {
						this.tableActionsMenu = [];
					}
					this.tableActionsMenu.push(actionMenuItem);
				}
			});

			if (this.tableActionsMenu !== undefined) {
				this.hasTableActions = (this.tableActionsMenu.length > 0);
			}

			if (this.bulkActionsMenu !== undefined) {
				if (this.bulkActionsMenu.length > 0 && this.selectionCheckbox !== undefined) {
					throw new Error('selectionCheckbox cannot be set if Data Table has Bulk Operations. Please set selectionCheckbox to undefined');
				}

				this.hasBulkActions = (this.bulkActionsMenu.length > 0);
			}
		}
	}

	private getURLSearchParams(data: DataTableLazyLoadEvent): HttpParams {
		let params: HttpParams = new HttpParams();

		params = params.set('$take', data.take.toString())
			.set('$offset', data.offset.toString());

		if (data.orderBy !== undefined) {
			params = params.set('$orderby', data.orderBy);
		}

		if (data.advancedSearchData !== undefined) {
			params = params.set('$filter', data.advancedSearchData.filterString);
		}

		return params;
	}

	exportAllToCsv = () => {
		this.exportToCsvRequested.emit(true);
	}
}
