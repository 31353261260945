import { Injectable } from '@angular/core';
import { MessageService, Message } from 'primeng/api';
import { ToastMessage, ToastMessagePosition } from '../../shared/models/general/toast-message.model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ToastDisplayService {
	toastPosition: Observable<string>;
	private toastPositionSubject: Subject<string> = new Subject<string>();

	constructor(private messageService: MessageService) {
		this.toastPosition = this.toastPositionSubject.asObservable();
	}

	addMessage(toastMessage: ToastMessage, toastPosition = ToastMessagePosition.topRight) {
		this.clear();
		this.setPosition(toastPosition);
		this.messageService.add({ severity: toastMessage.type, summary: toastMessage.title, detail: toastMessage.description });
	}

	addMultipleMessage(toastMsgs: ToastMessage[], toastPosition = ToastMessagePosition.topRight) {
		this.clear();
		this.setPosition(toastPosition);
		const msgsToAdd: Message[] = toastMsgs.map(toastMsg => {
			return {
				severity: toastMsg.type,
				summary: toastMsg.title,
				detail: toastMsg.description
			};
		});

		this.messageService.addAll(msgsToAdd);
	}

	setPosition(toastPosition: ToastMessagePosition) {
		this.toastPositionSubject.next(toastPosition);
	}

	private clear() {
		this.messageService.clear();
	}
}
