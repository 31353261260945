import { MyProfileComponent } from 'src/app/my-profile/my-profile.component';
import { HomeComponent } from '../../home';
import { AppGuard } from '../../shared';
var ɵ0 = function () { return import("./../../group-rights/group-rights.module.ngfactory").then(function (m) { return m.GroupRightsModuleNgFactory; }); }, ɵ1 = function () { return import("./../../user-rights/user-rights.module.ngfactory").then(function (m) { return m.UserRightsModuleNgFactory; }); }, ɵ2 = function () { return import("./../../tenant-management/tenant-management.module.ngfactory").then(function (m) { return m.TenantManagementModuleNgFactory; }); }, ɵ3 = function () { return import("./../../player-management/player-management.module.ngfactory").then(function (m) { return m.PlayerManagementModuleNgFactory; }); }, ɵ4 = function () { return import("./../../reports/reports.module.ngfactory").then(function (m) { return m.ReportsModuleNgFactory; }); }, ɵ5 = function () { return import("./../../financial/financial.module.ngfactory").then(function (m) { return m.FinancialModuleNgFactory; }); }, ɵ6 = function () { return import("./../../wallet/wallet.module.ngfactory").then(function (m) { return m.WalletModuleNgFactory; }); }, ɵ7 = function () { return import("./../../draw-processing/draw-processing.module.ngfactory").then(function (m) { return m.DrawProcessingModuleNgFactory; }); }, ɵ8 = function () { return import("./../../ticket-management/ticket-management.module.ngfactory").then(function (m) { return m.TicketManagementModuleNgFactory; }); }, ɵ9 = function () { return import("./../../retail-agent/retail-agent.module.ngfactory").then(function (m) { return m.RetailAgentModuleNgFactory; }); };
export var SECURE_ROUTES = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AppGuard]
    },
    {
        path: 'my-profile',
        component: MyProfileComponent,
        canActivate: [AppGuard]
    },
    {
        path: 'group-management',
        loadChildren: ɵ0,
        canActivate: [AppGuard]
    },
    {
        path: 'user-management',
        loadChildren: ɵ1,
        canActivate: [AppGuard]
    },
    {
        path: 'tenant-management',
        loadChildren: ɵ2,
        canActivate: [AppGuard]
    },
    {
        path: 'player-management',
        loadChildren: ɵ3,
        canActivate: [AppGuard]
    },
    {
        path: 'reports',
        loadChildren: ɵ4,
        canActivate: [AppGuard]
    },
    {
        path: 'financial',
        loadChildren: ɵ5,
        canActivate: [AppGuard]
    },
    {
        path: 'wallet',
        loadChildren: ɵ6,
        canActivate: [AppGuard]
    },
    {
        path: 'draw-processing',
        loadChildren: ɵ7,
        canActivate: [AppGuard]
    },
    {
        path: 'ticket-management',
        loadChildren: ɵ8,
        canActivate: [AppGuard]
    },
    {
        path: 'retail-agents',
        loadChildren: ɵ9,
        canActivate: [AppGuard]
    }
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
