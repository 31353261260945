import {HttpParams} from '@angular/common/http';
import {PlayerTrail} from '../../../wallet';
import {CurrencySetting} from '../../enums';
import {FileDownloadUtility} from './file-download.utility';
import {PlayersService} from '../../../player-management';
import {BoErrorHandlerService} from '../../../helio-core-services';

export class ExportCSVUtility {
	/**
	 * Function to get the HttpParams for CSV Exports
	 * @param currentHttpParams Current Http Params being used in requests
	 * @param selectedData List of the selected data from the list being viewed
	 * @param isAllDataSelected Boolean signifying whether the whole list is selected or not - useful when list is lazy loaded
	 * @param filterPropertyName Property name to be used in the filter when not all data is selected
	 * @param objectPropertyName Object property name to be used in the filter to get the data to filter with when not all data is selected
	 * @param additionalFilters Any additional filter to be added
	 */
	public static getHttpParams(
		currentHttpParams: HttpParams = new HttpParams(),
		selectedData: any[] = [],
		isAllDataSelected: boolean = true,
		filterPropertyName?: string,
		objectPropertyName?: string,
		...additionalFilters: string[]): HttpParams {

		if (!selectedData) {
			selectedData = [];
		}

		if (selectedData.length > 0 && (filterPropertyName === undefined || filterPropertyName === ''
			|| objectPropertyName === undefined || objectPropertyName === '')) {

			throw new Error('Cannot set filter without specifying both filterPropertyName and objectPropertyName');
		}

		selectedData = (isAllDataSelected) ? [] : selectedData;

		currentHttpParams = currentHttpParams.set('$offset', '0');

		const filterFragments: string[] = [];
		if (currentHttpParams.has('$filter')) {
			filterFragments.push(currentHttpParams.get('$filter'));
		}

		if (additionalFilters.length > 0) {
			const filters = additionalFilters.map(temp => `(${temp})`).join(' AND ');
			filterFragments.push(filters);
		}

		if (selectedData.length === 0) {
			currentHttpParams = currentHttpParams.set('$take', '1000000');
		} else {
			currentHttpParams = currentHttpParams.set('$take', selectedData.length.toString());

			const idFilterFragments: string = selectedData
				.map(tempData => `${filterPropertyName} eq ${tempData[objectPropertyName].toString()}`)
				.join(' OR ');

			filterFragments.push(`(${idFilterFragments})`);
		}

		if (filterFragments.length > 0) {
			currentHttpParams = currentHttpParams.set('$filter', `${filterFragments.join(' AND ')}`);
		}

		currentHttpParams = currentHttpParams.set('format', 'csv');

		return currentHttpParams;
	}


	/**
	 * Helper method for generating {@link HttpParams}.
	 */
	private static makeExportHttpParams(opts: {
											currencySetting: CurrencySetting,
											params: HttpParams
										},
										selectedData?: PlayerTrail[],
										isAllDataSelected?: boolean): HttpParams {
		// passing currency for CSV
		const currentCurrency = CurrencySetting[opts.currencySetting].charAt(0).toUpperCase() +
			CurrencySetting[opts.currencySetting].slice(1);
		opts.params = opts.params.set('$params', `currency == String ${currentCurrency}`);

		return ExportCSVUtility.getHttpParams(
			opts.params, selectedData, isAllDataSelected, 'TransactionID', 'transactionID'
		);
	}

	public static exportPlayerTransactionsToCSV(playerId: number,
												opts: {
													currencySetting: CurrencySetting,
													params: HttpParams,
													playersService: PlayersService,
													boErrorHandlerService: BoErrorHandlerService
												},
												selectedData?: PlayerTrail[],
												isAllDataSelected?: boolean) {
		const exportHttpParams = ExportCSVUtility.makeExportHttpParams(opts, selectedData, isAllDataSelected);
		opts.playersService.getPlayerTransactionsCsv(exportHttpParams)
			.subscribe(
				res => {
					if (res !== undefined) {
						// download file
						FileDownloadUtility.downloadCsv(res, `PlayerTrailForPlayer_${playerId}`);
					}
				},
				error => {
					opts.boErrorHandlerService.handleError(error);
				}
			);
	}

	public static exportCouponTransactionsToCSV(couponId: string,
												opts: {
													currencySetting: CurrencySetting,
													params: HttpParams,
													playersService: PlayersService,
													boErrorHandlerService: BoErrorHandlerService
												},
												selectedData?: PlayerTrail[],
												isAllDataSelected?: boolean) {
		const exportHttpParams = ExportCSVUtility.makeExportHttpParams(opts, selectedData, isAllDataSelected);
		opts.playersService.getCouponTransactionsCsv(couponId, exportHttpParams)
			.subscribe(
				res => {
					if (res !== undefined) {
						// download file
						FileDownloadUtility.downloadCsv(res, `PlayerTrailForCoupon_${couponId}`);
					}
				},
				error => {
					opts.boErrorHandlerService.handleError(error);
				}
			);
	}
}
