import { HttpParams } from '@angular/common/http';
import { CurrencySetting } from '../../enums';
import { FileDownloadUtility } from './file-download.utility';
var ExportCSVUtility = /** @class */ (function () {
    function ExportCSVUtility() {
    }
    /**
     * Function to get the HttpParams for CSV Exports
     * @param currentHttpParams Current Http Params being used in requests
     * @param selectedData List of the selected data from the list being viewed
     * @param isAllDataSelected Boolean signifying whether the whole list is selected or not - useful when list is lazy loaded
     * @param filterPropertyName Property name to be used in the filter when not all data is selected
     * @param objectPropertyName Object property name to be used in the filter to get the data to filter with when not all data is selected
     * @param additionalFilters Any additional filter to be added
     */
    ExportCSVUtility.getHttpParams = function (currentHttpParams, selectedData, isAllDataSelected, filterPropertyName, objectPropertyName) {
        if (currentHttpParams === void 0) { currentHttpParams = new HttpParams(); }
        if (selectedData === void 0) { selectedData = []; }
        if (isAllDataSelected === void 0) { isAllDataSelected = true; }
        var additionalFilters = [];
        for (var _i = 5; _i < arguments.length; _i++) {
            additionalFilters[_i - 5] = arguments[_i];
        }
        if (!selectedData) {
            selectedData = [];
        }
        if (selectedData.length > 0 && (filterPropertyName === undefined || filterPropertyName === ''
            || objectPropertyName === undefined || objectPropertyName === '')) {
            throw new Error('Cannot set filter without specifying both filterPropertyName and objectPropertyName');
        }
        selectedData = (isAllDataSelected) ? [] : selectedData;
        currentHttpParams = currentHttpParams.set('$offset', '0');
        var filterFragments = [];
        if (currentHttpParams.has('$filter')) {
            filterFragments.push(currentHttpParams.get('$filter'));
        }
        if (additionalFilters.length > 0) {
            var filters = additionalFilters.map(function (temp) { return "(" + temp + ")"; }).join(' AND ');
            filterFragments.push(filters);
        }
        if (selectedData.length === 0) {
            currentHttpParams = currentHttpParams.set('$take', '1000000');
        }
        else {
            currentHttpParams = currentHttpParams.set('$take', selectedData.length.toString());
            var idFilterFragments = selectedData
                .map(function (tempData) { return filterPropertyName + " eq " + tempData[objectPropertyName].toString(); })
                .join(' OR ');
            filterFragments.push("(" + idFilterFragments + ")");
        }
        if (filterFragments.length > 0) {
            currentHttpParams = currentHttpParams.set('$filter', "" + filterFragments.join(' AND '));
        }
        currentHttpParams = currentHttpParams.set('format', 'csv');
        return currentHttpParams;
    };
    /**
     * Helper method for generating {@link HttpParams}.
     */
    ExportCSVUtility.makeExportHttpParams = function (opts, selectedData, isAllDataSelected) {
        // passing currency for CSV
        var currentCurrency = CurrencySetting[opts.currencySetting].charAt(0).toUpperCase() +
            CurrencySetting[opts.currencySetting].slice(1);
        opts.params = opts.params.set('$params', "currency == String " + currentCurrency);
        return ExportCSVUtility.getHttpParams(opts.params, selectedData, isAllDataSelected, 'TransactionID', 'transactionID');
    };
    ExportCSVUtility.exportPlayerTransactionsToCSV = function (playerId, opts, selectedData, isAllDataSelected) {
        var exportHttpParams = ExportCSVUtility.makeExportHttpParams(opts, selectedData, isAllDataSelected);
        opts.playersService.getPlayerTransactionsCsv(exportHttpParams)
            .subscribe(function (res) {
            if (res !== undefined) {
                // download file
                FileDownloadUtility.downloadCsv(res, "PlayerTrailForPlayer_" + playerId);
            }
        }, function (error) {
            opts.boErrorHandlerService.handleError(error);
        });
    };
    ExportCSVUtility.exportCouponTransactionsToCSV = function (couponId, opts, selectedData, isAllDataSelected) {
        var exportHttpParams = ExportCSVUtility.makeExportHttpParams(opts, selectedData, isAllDataSelected);
        opts.playersService.getCouponTransactionsCsv(couponId, exportHttpParams)
            .subscribe(function (res) {
            if (res !== undefined) {
                // download file
                FileDownloadUtility.downloadCsv(res, "PlayerTrailForCoupon_" + couponId);
            }
        }, function (error) {
            opts.boErrorHandlerService.handleError(error);
        });
    };
    return ExportCSVUtility;
}());
export { ExportCSVUtility };
