import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities';
var PagePermissionsService = /** @class */ (function (_super) {
    tslib_1.__extends(PagePermissionsService, _super);
    function PagePermissionsService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.PAGE_PERMISSIONS_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    PagePermissionsService.prototype.getPages = function () {
        return this.get();
    };
    return PagePermissionsService;
}(BaseService));
export { PagePermissionsService };
