<div class="layout-wrapper" (click)="onWrapperClick()" [ngClass]="{'layout-wrapper-static': layoutStatic,
				'layout-wrapper-active': mobileMenuActive,
				'layout-menu-horizontal': layoutHorizontal,
				'layout-rtl': isRTL}">

	<he-app-loader></he-app-loader>
	<he-navigation></he-navigation>

	<div class="layout-main">
		<he-topbar></he-topbar>
		<he-breadcrumb></he-breadcrumb>

		<div class="layout-content">
			<div class="ui-g ui-fluid">
				<div class="ui-g-12">

					<div class="card card-w-title">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>

		<div class="layout-main-mask" *ngIf="mobileMenuActive" (click)="onMaskClick($event)"></div>
	</div>
</div>

<ng-template #overlayHost heComponentHost></ng-template>