import { Component, Input, OnInit, ComponentRef, OnDestroy, Output, EventEmitter, HostBinding } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { SelectItem } from 'primeng/api';

import { SearchField } from '../search-field.model';
import { SearchFieldType } from '../search-field-type.enum';
import { AdvancedSearchOperators } from '../search-field-operators.model';
import { SearchOperator } from '../search-operator.enum';
import { SearchOperatorData } from '../search-operator-data';

@Component({
	selector: 'he-advanced-search-field',
	styleUrls: [
		'./advanced-search-field.component.scss'
	],
	templateUrl: 'advanced-search-field.component.html',
	animations: [
		trigger('animate', [
			transition(':enter', [
				style({ opacity: 0, transform: 'translateX(-10px)' }),
				animate('250ms', style({ opacity: 1, transform: 'translateX(0px)' }))
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('250ms', style({ opacity: 0, transform: 'translateX(10px)' }))
			])
		])
	]
})

export class AdvancedSearchFieldComponent implements OnInit, OnDestroy {
	@Input() searchField: SearchField;
	@Input() searchFieldComponentRef: ComponentRef<AdvancedSearchFieldComponent>;
	@Input() searchFieldID: number;
	@Input() isFirstSearchField = true;

	@Output() destroyedComponent: EventEmitter<number> = new EventEmitter<number>();

	@HostBinding('@animate') animateSearchField = true;

	searchFieldType: typeof SearchFieldType = SearchFieldType;
	searchOperatorType: typeof SearchOperator = SearchOperator;

	dropdownSearchFieldOperators: SearchOperatorData[];
	selectedOperator: SearchOperator;

	searchAndOr: SelectItem[];
	selectedSearchAndOr: string;

	numberRangeMinValue: number;
	numberRangeMaxValue: number;

	booleanOptions: SelectItem[];

	operatorInputID: string;
	inputID: string;
	input2ID: string;

	ngOnInit() {
		if (this.searchField.type === SearchFieldType.Boolean) {
			this.booleanOptions = [
				{ label: 'true', value: true },
				{ label: 'false', value: false }
			];
		}

		this.searchAndOr = [
			{ label: 'AND', value: 'AND' },
			{ label: 'OR', value: 'OR' }
		];

		this.selectedSearchAndOr = this.searchAndOr[0].value;

		this.getSearchFieldOperatorsData();
		this.selectedOperator = this.dropdownSearchFieldOperators[0].value;

		this.inputID = new Date().getTime().toString();
		this.input2ID = `${this.inputID}-2`;
		this.operatorInputID = `${this.inputID}-operator`;
	}

	ngOnDestroy() {
		this.destroyedComponent.emit(this.searchFieldID);
	}

	selectSearchOperator(selectedSearchOperator: SearchOperator) {
		if (this.searchField.hasRange) {
			this.clearRange(selectedSearchOperator);
		}

		this.selectedOperator = selectedSearchOperator;

		this.updateFilterString(this.searchField.searchValue, this.searchField);
	}

	updateFilterString(newValue: any, searchField: SearchField): void {
		if (newValue === undefined || newValue === null) {
			searchField.searchValue = undefined;
			searchField.filterString = undefined;
			return;
		}

		searchField.searchValue = newValue;

		const property = searchField.property;
		const value = searchField.searchValue;
		const operator = SearchOperatorData.item(this.selectedOperator).filterOperatorString;
		let filterString: string;

		switch (searchField.type) {
			case SearchFieldType.String:
				if (value !== '') {
					filterString = `${property} ${operator} "${value}"`;
				}
				break;
			case SearchFieldType.Boolean:
				filterString = `${property} ${operator} ${value}`;
				break;
			case SearchFieldType.Date:
				if (this.selectedOperator !== SearchOperator.BETWEEN) {
					const dateValue = (value as Date);
					dateValue.setSeconds(0);
					dateValue.setMilliseconds(0);

					const strDate = dateValue.toISOString();
					filterString = `${property} ${operator} DATETIME "${strDate}"`;
				} else {
					if (value[0] !== null && value[1] !== null) {
						const minDateValue = (value[0] as Date);
						minDateValue.setMilliseconds(0);

						const maxDateValue = (value[1] as Date);
						maxDateValue.setMilliseconds(0);

						const strDateMin = minDateValue.toISOString();
						const strDateMax = maxDateValue.toISOString();

						filterString = `(${property} ge DATETIME "${strDateMin}" AND ${property} le DATETIME "${strDateMax}")`;
					}
				}
				break;
			case SearchFieldType.Number:
				if (this.selectedOperator !== SearchOperator.BETWEEN) {
					filterString = `${property} ${operator} ${value}`;
				} else {
					if (value[0] !== null && value[1] !== null) {
						filterString = `(${property} ge ${value[0]} AND ${property} le ${value[1]})`;

						this.numberRangeMinValue = value[0];
						this.numberRangeMaxValue = value[1];
					}
				}
				break;
			case SearchFieldType.List:
				if (searchField.isArraySearch) {
					if ((value as any[]).length > 0) {
						filterString = (value as any[]).map(temp => {
							return `${property} == ${temp}`;
						}).join(' OR ');

						filterString = `${searchField.listProperty}.Any(${filterString})`;
					}
				} else {
					if ((value as any[]).length > 0) {
						filterString = (value as any[]).map(temp => {
							return `${property} ${operator} ${temp}`;
						}).join(' OR ');

						filterString = `(${filterString})`;
					}
				}
				break;
			default:
				break;
		}

		searchField.filterString = filterString;
	}

	delete() {
		if (this.searchFieldComponentRef) {
			this.searchFieldComponentRef.destroy();
		}
	}

	private getSearchFieldOperatorsData() {
		const searchFieldOperators = AdvancedSearchOperators.item(this.searchField.type).slice();

		// if type is date or number,
		// remove between operator if SearchField.hasRange is not true
		if (this.searchField.type === SearchFieldType.Date || this.searchField.type === SearchFieldType.Number) {
			if (this.searchField.hasRange !== true) {
				const index = searchFieldOperators.findIndex(temp => temp === SearchOperator.BETWEEN);
				if (index !== -1) {
					searchFieldOperators.splice(index, 1);
				}
			}
		}

		this.dropdownSearchFieldOperators = searchFieldOperators.map(temp => {
			return SearchOperatorData.item(temp);
		});
	}

	private setNumberRange() {
		this.searchField.searchValue = [
			this.searchField.minNumberRange,
			this.searchField.maxNumberRange
		];

		this.numberRangeMinValue = this.searchField.minNumberRange;
		this.numberRangeMaxValue = this.searchField.maxNumberRange;
	}

	// when switching from any operator to BETWEEN or vice-versa, searchValue needs to be cleared/set
	// this has to be done as on operator dropdown change the filterString is updated
	private clearRange(newOperator: SearchOperator) {
		// if date set searchValue to undefined
		if (this.searchField.type === SearchFieldType.Date) {
			if (this.selectedOperator === SearchOperator.BETWEEN || newOperator === SearchOperator.BETWEEN) {
				this.searchField.searchValue = undefined;
			}
		}

		// if number set searchValue to number range values when operator is set to BETWEEN
		// else set searchValue to undefined when operator was set to BETWEEN
		if (this.searchField.type === SearchFieldType.Number) {
			if (this.selectedOperator === SearchOperator.BETWEEN) {
				this.searchField.searchValue = undefined;
			} else if (newOperator === SearchOperator.BETWEEN) {
				this.setNumberRange();
			}
		}
	}
}
