import { ValidatorFn, AbstractControl } from '@angular/forms';

export const atLeastOneNumberRegEx = /\d/;

export function atLeastOneNumber(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const hasNumber = atLeastOneNumberRegEx.test(control.value);
		return hasNumber ? null : { 'hasNumber': { value: control.value } };
	};
}

export const atLeastOneLowerCaseLetterRegEx = /[a-z]/;

export function atLeastOneLowerCaseLetter(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const hasLowerCase = atLeastOneLowerCaseLetterRegEx.test(control.value);
		return hasLowerCase ? null : { 'hasLowerCase': { value: control.value } };
	};
}

export const atLeastOneUpperCaseLetterRegEx = /[A-Z]/;

export function atLeastOneUpperCaseLetter(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const hasUpperCase = atLeastOneUpperCaseLetterRegEx.test(control.value);
		return hasUpperCase ? null : { 'hasUpperCase': { value: control.value } };
	};
}

export const atLeastOneSymbolRegEx = /[#()`~!@$%^&-+*/_=,;.'":|\(\)\[\]\{\}\\\-]/;

export function atLeastOneSymbol(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const hasSymbol = atLeastOneSymbolRegEx.test(control.value);
		return hasSymbol ? null : { 'hasSymbol': { value: control.value } };
	};
}

export function sameString(string: string): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const isSameString = string === control.value;
		return isSameString ? { 'isSameString': { value: control.value } } : null;
	};
}

export function isNotSameString(string: string): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const isSameString = string === control.value;
		return isSameString ? null : { 'isNotSameString': { value: control.value } };
	};
}

export function containsUsername(string: string): ValidatorFn {
	const regex = new RegExp(string, 'i');

	return (control: AbstractControl): { [key: string]: any } => {
		const hasUsername = regex.test(control.value);
		return hasUsername ? { 'containsUsername': { value: control.value } } : null;
	};
}
