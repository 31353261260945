import { OnInit } from '@angular/core';
var NavigationComponent = /** @class */ (function () {
    function NavigationComponent(router, loginService, appLayoutService, appConfigService) {
        this.router = router;
        this.loginService = loginService;
        this.appLayoutService = appLayoutService;
        this.appConfigService = appConfigService;
        this.reset = false;
        this.sidebarActive = true;
        this.isMenuStatic = true;
        this.userName = '';
    }
    NavigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userName = localStorage.getItem('user_name');
        this.setMenuItems();
        this.appLayoutService.toggleMenuLayoutClick.subscribe(function (isMenuStatic) {
            _this.isMenuStatic = isMenuStatic;
        });
        this.isMenuStatic = this.appLayoutService.isMenuStatic;
        this.sidebarActive = this.appLayoutService.isMenuStatic;
    };
    NavigationComponent.prototype.setMenuItems = function () {
        this.menuItems = [
            {
                label: 'User Management', icon: 'person', routeName: 'user-management', items: [
                    { label: 'Users', icon: 'person', routeName: 'user-management', routerLink: ['/user-management/users'] },
                    { label: 'Groups', icon: 'people', routeName: 'group-management', routerLink: ['/group-management/groups'] }
                ]
            },
            {
                label: 'Tenant Management', icon: 'work', routeName: 'tenant-management', routerLink: ['/tenant-management']
            },
            {
                label: 'Player Management', icon: 'account_circle', routeName: 'player-management', items: [
                    { label: 'Players', icon: 'account_circle', routeName: 'player-management/players', routerLink: ['/player-management/players'] },
                    // wallet player management
                    { label: 'Players', icon: 'account_circle', routeName: 'wallet/wallet-players', routerLink: ['/wallet/wallet-players'] },
                    { label: 'KYC', icon: 'folder_shared', routeName: 'player-management/kyc', routerLink: ['/player-management/kyc'] },
                    {
                        label: 'Winning Participations', icon: 'local_play', routeName: 'player-management/winning-participations',
                        routerLink: ['/player-management/winning-participations']
                    }
                ]
            },
            {
                label: 'Ticket Management', icon: 'local_offer', routeName: 'ticket-management', items: [
                    {
                        label: 'Issue Batch', icon: 'queue',
                        routeName: 'ticket-management/issue-batch', routerLink: ['/ticket-management/issue-batch']
                    },
                    {
                        label: 'Distributed Batches', icon: 'donut_small',
                        routeName: 'ticket-management/distributed-batches', routerLink: ['/ticket-management/distributed-batches']
                    },
                    {
                        label: 'Subscriptions', icon: 'book',
                        routeName: 'ticket-management/subscriptions', routerLink: ['/ticket-management/subscriptions']
                    }
                ]
            },
            {
                label: 'Draw Processing', icon: 'gradient', routeName: 'draw-processing', items: [
                    // {
                    // 	label: 'Current Draws', icon: 'gradient',
                    // 	routeName: 'draw-processing/current-draws', routerLink: ['/draw-processing/current-draws']
                    // },
                    {
                        label: 'Upcoming Draws', icon: 'hdr_strong',
                        routeName: 'draw-processing/upcoming-draws', routerLink: ['/draw-processing/upcoming-draws']
                    },
                    {
                        label: 'Previous Draws', icon: 'hdr_weak',
                        routeName: 'draw-processing/previous-draws', routerLink: ['/draw-processing/previous-draws']
                    }
                ]
            },
            {
                label: 'Wallet Transactions', icon: 'account_balance_wallet', routeName: 'wallet', routerLink: ['/wallet']
            },
            {
                label: 'Reports', icon: 'show_chart', routeName: 'reports', routerLink: ['/reports']
            },
            {
                label: 'Finance', icon: 'account_balance_wallet', routeName: 'financial', items: [
                    {
                        label: 'Billing Aggregates', icon: 'account_balance_wallet',
                        routeName: 'financial/daily-aggregates', routerLink: ['/financial/daily-aggregates']
                    },
                    { label: 'Billing Invoices', icon: 'receipt', routeName: 'financial/billing-invoices', routerLink: ['/financial/billing-invoices'] },
                    { label: 'Bank Accounts', icon: 'account_balance', routeName: 'financial/bank-accounts', routerLink: ['/financial/bank-accounts'] },
                    {
                        label: 'Nominal Mappings', icon: 'compare_arrows',
                        routeName: 'financial/bank-nominal-mappings', routerLink: ['/financial/bank-nominal-mappings']
                    }
                ]
            },
            {
                label: 'Retail Management', icon: 'contacts', routeName: 'retail-agents', items: [
                    { label: 'Find Ticket', icon: 'search', routeName: 'retail-agents/find-ticket', routerLink: ['/retail-agents/find-ticket'] },
                    { label: 'Agents', icon: 'recent_actors', routeName: 'retail-agents/agents', routerLink: ['/retail-agents/agents'] },
                    {
                        label: 'Reconciliation', icon: 'playlist_add_check',
                        routeName: 'retail-agents/reconciliation', routerLink: ['/retail-agents/reconciliation']
                    }
                ]
            }
        ];
        // renaming player menu items if user has rights to both player management pages
        // remove when wallet functionality is removed!
        if (this.checkIfHasWalletPageRights('wallet/wallet-players') && this.checkIfHasWalletPageRights('player-management/players')) {
            this.menuItems[2].items[0].label = 'Engine Players';
            this.menuItems[2].items[1].label = 'Wallet Players';
        }
    };
    NavigationComponent.prototype.onSidebarClick = function (event) {
        this.appLayoutService.menuClick = true;
    };
    NavigationComponent.prototype.activateNavItem = function (navItem) {
        this.userName = localStorage.getItem('user_name');
        // set active to false for the current activateNavItem
        if (this.activeNavItem === undefined) {
            this.activeNavItem = navItem;
        }
        else {
            this.activeNavItem.isActive = false;
        }
        navItem.isActive = true;
        this.activeNavItem = navItem;
    };
    NavigationComponent.prototype.toggleMenu = function () {
        this.appLayoutService.toggleMenuLayout();
    };
    NavigationComponent.prototype.mouseOverLeaveHandler = function (isActive) {
        this.sidebarActive = this.appLayoutService.isMobile() ? true : isActive;
        this.appLayoutService.sidebarActive(this.sidebarActive);
    };
    // remove when wallet functionality is removed!
    NavigationComponent.prototype.checkIfHasWalletPageRights = function (url) {
        var urlList = url.split('/');
        var localStorageList = JSON.parse(JSON.parse(localStorage.getItem('access_rights')).Pages);
        var hasRights = true;
        var route;
        var _loop_1 = function (i) {
            if (urlList[i] !== '') {
                var tempURL_1 = urlList[i];
                var pagePermissions = (i === 0) ? localStorageList : route.ChildPages;
                route = pagePermissions.find(function (tempPageObj) {
                    return tempPageObj.Name === tempURL_1;
                });
                if (route === undefined) {
                    hasRights = false;
                    return "break";
                }
            }
        };
        for (var i = 0; i < urlList.length; i++) {
            var state_1 = _loop_1(i);
            if (state_1 === "break")
                break;
        }
        return hasRights;
    };
    return NavigationComponent;
}());
export { NavigationComponent };
