import { of } from 'rxjs';
var AppGuard = /** @class */ (function () {
    function AppGuard(router, loginService) {
        this.router = router;
        this.loginService = loginService;
    }
    AppGuard.prototype.canActivate = function (next) {
        if (!this.loginService.isLoggedIn && this.loginService.isInactivityDateLimitExpired) {
            return this.logoutAndRedirect();
        }
        var hasPageRigts = this.checkPageRights(next);
        if (!hasPageRigts) {
            return this.redirectToNoAccessRights();
        }
        return of(true);
    };
    AppGuard.prototype.checkPageRights = function (next) {
        var urlSegments = this.getURLSegments(next);
        // FOR DEV ONLY ---
        if (urlSegments.includes("my-profile"))
            return true;
        // ----
        // get page rights object from token/local storage
        // loop in object to find current page
        var localStorageList = JSON.parse(JSON.parse(localStorage.getItem('access_rights')).Pages);
        var pageChildren = [];
        var _loop_1 = function (i) {
            var urlSegment = urlSegments[i];
            var listToCheck = (i === 0) ? localStorageList : pageChildren;
            var page = listToCheck.find(function (temp) {
                return temp.Name === urlSegment;
            });
            if (page === undefined) {
                return { value: false };
            }
            pageChildren = page.ChildPages;
        };
        for (var i = 0; i < urlSegments.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return true;
    };
    AppGuard.prototype.getURLSegments = function (next) {
        var tempURLSegments = [];
        var pathFromRoot = next.pathFromRoot;
        pathFromRoot.forEach(function (temp) {
            if (temp.url.length > 0) {
                tempURLSegments.push(temp.url[0].path);
            }
        });
        return tempURLSegments;
    };
    AppGuard.prototype.redirectToNoAccessRights = function () {
        var queryParams = { redirectURL: this.router.url };
        this.router.navigate(['no-access-rights'], { queryParams: queryParams });
        return of(false);
    };
    AppGuard.prototype.logoutAndRedirect = function () {
        this.loginService.logoutAndRedirect();
        return of(false);
    };
    return AppGuard;
}());
export { AppGuard };
