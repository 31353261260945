import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './validation.service';
@Component({
	selector: 'he-form-messages',
	styleUrls: [
		'./form-messages.component.scss'
	],
	templateUrl: './form-messages.component.html'
})
export class FormMessagesComponent implements OnInit, OnChanges {

	errorMessage: string;

	@Input() errors: FormControl;
	@Input() reaction: FormControl;

	ngOnInit() {
		// console.log(this.errorMessage);
	}

	constructor() {
		//
	}

	ngOnChanges() {
		this.setErrorMessage();
	}

	setErrorMessage(): string {

		if (this.errors !== null) {
			for (const fieldName in this.errors) {
				if (this.errors.hasOwnProperty(fieldName) && this.reaction) {
					return this.errorMessage = ValidationService.getValidatorErrorMessage(fieldName, this.errors[fieldName]);
				}
			}
		} else {
			return this.errorMessage = null;
		}
	}
}
