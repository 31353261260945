export class PadNumberUtility {
	/**
	 * Function stringifies number and pads it with leading zeros
	 * @param num Number to Pad
	 * @param padLength Whole length of output string
	 * @param paddingChar Character to pad with (if not 0)
	 */
	public static padStartNumber(num: number, padLength: number, paddingChar: string = '0'): string {
		const pad = new Array(1 + padLength).join(paddingChar);
		return (pad + num).slice(-pad.length);
	}
}
