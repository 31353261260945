/**
 * Class is a repository for the 'action' part of the URLs to be generated when making HTTP requests
 */
export class WalletServiceAction {
	// TRANSACTIONS ACTIONS
	public static GET_DEPOSITS = 'deposits';
	public static GET_WITHDRAWALS = 'withdraws';

	// PLAYERS ACTIONS
	public static GET_PLAYERS = '';
	public static GET_PLAYER_STATS = 'playerstats';

	// GAME ACTIONS
	public static AWARD_FREE_TICKETS = 'awardfreetickets';
}
