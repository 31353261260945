import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { AppLayoutService } from '../../layout/secure/app-layout.service';

@Component({
	/* tslint:disable:component-selector */
	selector: '[app-submenu]',
	/* tslint:enable:component-selector */
	template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *heShowNavItem="child.routeName">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                    (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text">{{child.label}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                    (mouseenter)="hover=true" (mouseleave)="hover=false" class="ripplelink">
                    <i class="material-icons">{{child.icon}}</i>
                    <span class="menuitem-text">{{child.label}}</span>
                    <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
                    [@children]="(isHorizontal)&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
	animations: [
		trigger('children', [
			state('hiddenAnimated', style({
				height: '0px'
			})),
			state('visibleAnimated', style({
				height: '*'
			})),
			state('visible', style({
				height: '*',
				'z-index': 100
			})),
			state('hidden', style({
				height: '0px',
				'z-index': '*'
			})),
			transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class AppSubMenuComponent implements OnInit {

	@Input() item: MenuItem;

	@Input() root: boolean;

	@Input() visible: boolean;

	_reset: boolean;

	activeIndex: number;

	hover: boolean;

	isHorizontal = false;

	// constructor(public app: AppComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) { }
	constructor(public router: Router, public location: Location, private appLayoutService: AppLayoutService) { }

	ngOnInit() {
		this.isHorizontal = this.appLayoutService.isMenuHorizontal;
	}

	itemClick(event: Event, item: MenuItem, index: number)  {
		// avoid processing disabled items
		if (item.disabled) {
			event.preventDefault();
			return true;
		}

		// activate current item and deactivate active sibling if any
		if (item.routerLink || item.items || item.command || item.url) {
			this.activeIndex = (this.activeIndex === index) ? null : index;
		}

		// execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item });
		}

		// prevent hash change
		if (item.items || (!item.url && !item.routerLink)) {
			// setTimeout(() => {
			// 	this.appMenu.layoutMenuScrollerViewChild.moveBar();
			// }, 450);
			event.preventDefault();
		}

		// hide menu
		if (!item.items) {
			if (this.appLayoutService.isMobile()) {
				// this.appLayoutService.sidebarActive = false;
				this.appLayoutService.menuActiveSubject.next(false);
			}
		}
	}

	isActive(index: number): boolean {
		return this.activeIndex === index;
	}

	@Input() get reset(): boolean {
		return this._reset;
	}

	set reset(val: boolean) {
		this._reset = val;
	}
}
