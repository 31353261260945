import { TemplateRef, ViewContainerRef } from '@angular/core';
var ShowNavItemDirective = /** @class */ (function () {
    function ShowNavItemDirective(templateRef, viewContainer) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(ShowNavItemDirective.prototype, "heShowNavItem", {
        set: function (url) {
            if (this.checkPagePermissions(url)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
            else {
                this.viewContainer.clear();
            }
        },
        enumerable: true,
        configurable: true
    });
    ShowNavItemDirective.prototype.checkPagePermissions = function (url) {
        var urlList = url.split('/');
        var localStorageList = JSON.parse(JSON.parse(localStorage.getItem('access_rights')).Pages);
        var hasRights = true;
        var route;
        var _loop_1 = function (i) {
            if (urlList[i] !== '') {
                var tempURL_1 = urlList[i];
                var pagePermissions = (i === 0) ? localStorageList : route.ChildPages;
                route = pagePermissions.find(function (tempPageObj) {
                    return tempPageObj.Name === tempURL_1;
                });
                if (route === undefined) {
                    hasRights = false;
                    return "break";
                }
            }
        };
        for (var i = 0; i < urlList.length; i++) {
            var state_1 = _loop_1(i);
            if (state_1 === "break")
                break;
        }
        return hasRights;
    };
    return ShowNavItemDirective;
}());
export { ShowNavItemDirective };
