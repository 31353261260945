import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ServiceController, ServiceAction } from '../../shared/utilities/service-utilities';
import { ResponseBodyType } from '../../shared/enums';
/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
var TenantService = /** @class */ (function (_super) {
    tslib_1.__extends(TenantService, _super);
    function TenantService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.TENANTS_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        _this.tenants = [];
        _this.retailTenants = [];
        _this.tenantTypes = [];
        _this.loggedOperatorIDs = JSON.parse(localStorage.getItem('access_rights')).TenantIDs;
        return _this;
    }
    TenantService.prototype.getTenants = function () {
        var _this = this;
        if (this.tenants.length === 0) {
            var tenantParams = new HttpParams()
                .set('$take', '1000')
                .set('$offset', '0')
                .set('$filter', 'isActive eq true');
            // commented as filtering should be done server side
            // if (this.loggedOperatorIDs.length > 0) {
            // 	let operatorIDs: string[];
            // 	operatorIDs = this.loggedOperatorIDs.split(',');
            // 	let filter = '';
            // 	operatorIDs.forEach(element => {
            // 		filter += `TenantID eq ${element} OR `;
            // 	});
            // 	const pos = filter.lastIndexOf(' OR ');
            // 	filter = filter.substring(0, pos);
            // 	tenantParams = new HttpParams()
            // 		.set('$take', operatorIDs.length.toString())
            // 		.set('$filter', filter);
            // }
            return this.getTenantsFromServer(tenantParams).pipe(map(function (res) { _this.tenants = res.resultSet; return _this.tenants; }));
        }
        else {
            return of(this.tenants);
        }
    };
    TenantService.prototype.getRetailTenants = function () {
        var _this = this;
        if (this.retailTenants.length === 0) {
            return this.getRetailTenantsFromServer().pipe(map(function (res) { _this.retailTenants = res.resultSet; return _this.retailTenants; }));
        }
        else {
            return of(this.retailTenants);
        }
    };
    TenantService.prototype.getTenantTypes = function () {
        var _this = this;
        if (this.tenantTypes.length === 0) {
            return this.getTenantTypesFromServer().pipe(map(function (res) { _this.tenantTypes = res; return _this.tenantTypes; }));
        }
        else {
            return of(this.tenantTypes);
        }
    };
    TenantService.prototype.getTenantsFromServer = function (searchParams) {
        return this.get(ServiceAction.GENERIC_GET, undefined, searchParams);
    };
    TenantService.prototype.getTenantsFromServerCsv = function (searchParams) {
        return this.get(ServiceAction.GENERIC_GET, undefined, searchParams, ResponseBodyType.Text);
    };
    TenantService.prototype.getRetailTenantsFromServer = function () {
        var searchParams = new HttpParams().set('$take', '10').set('$offset', '0').set('$filter', 'IntegrationTypeID eq 0');
        return this.get(ServiceAction.TENANTS_GETRETAIL, undefined, searchParams);
    };
    TenantService.prototype.getTenantTypesFromServer = function () {
        return this.get(ServiceAction.TENANTMANAGEMENT_TYPES);
    };
    TenantService.prototype.createOperatorOrBroker = function (isBroker, postData) {
        if (isBroker === void 0) { isBroker = false; }
        var action = !isBroker ? ServiceAction.TENANTMANAGEMENT_CREATEOPERATOR : ServiceAction.TENANTMANAGEMENT_CREATEBROKER;
        return this.post(action, postData);
    };
    TenantService.prototype.updateOperatorOrBroker = function (isBroker, editData) {
        if (isBroker === void 0) { isBroker = false; }
        var action = !isBroker ? ServiceAction.TENANTMANAGEMENT_UPDATEOPERATOR : ServiceAction.TENANTMANAGEMENT_UPDATEBROKER;
        return this.edit(action, editData);
    };
    TenantService.prototype.getContactTypes = function () {
        return this.get(ServiceAction.TENANTMANAGEMENT_CONTACTTYPES);
    };
    TenantService.prototype.getIntegrationTypes = function () {
        return this.get(ServiceAction.TENANTMANAGEMENT_INTEGRATIONTYPES);
    };
    return TenantService;
}(BaseService));
export { TenantService };
