import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
var AuditService = /** @class */ (function (_super) {
    tslib_1.__extends(AuditService, _super);
    function AuditService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.AUDIT_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    AuditService.prototype.getUserLogs = function (searchParams) {
        return this.get(ServiceAction.GENERIC_GET, undefined, searchParams);
    };
    return AuditService;
}(BaseService));
export { AuditService };
