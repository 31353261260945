import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
@Directive({
	selector: '[heCharsDirective]'
})
export class CharsDirective implements OnInit {

	@Input() type: string;
	@Input() event: string;

	constructor(private eltRef: ElementRef, private renderer: Renderer2) {

	}

	ngOnInit() {
		if (this[this.type]) {
			return this[this.type]();
		}
	}

	numeric() {
		this.renderer.listen(this.eltRef.nativeElement, this.event, (event) => {
			return event.charCode >= 48 && event.charCode <= 57;
		});
	}

	alphabetic() {
		this.renderer.listen(this.eltRef.nativeElement, this.event , (event) => {
			return event.charCode === 32 || (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
		});
	}

}
