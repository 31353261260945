import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';

import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
import { GamesData } from '../../shared/models/games/games-data.model';
import { GameGroup } from '../../shared/models/games/game-group.model';
import { Board } from '../../shared/models/games/board.model';


/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class GameAdministrationService extends BaseService {
	public allGames: any[] = [];
	public defaultGames: any[] = [];
	public secondaryGames: any[] = [];

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.GAMEADMINISTRATION_CONTROLLER, appConfigService.serviceBaseURL);
	}

	getAllGames() {
		if (this.allGames.length === 0) {
			return this.getAllGamesFromServer().pipe(
				map(res => { this.allGames = res; return this.allGames; }));
		} else {
			return of(this.allGames);
		}
	}

	getAllGamesFromServer() {
		return this.get(ServiceAction.GENERIC_GAMES);
	}

	getSecondaryGames(value?: any[]) {
		if (this.secondaryGames.length === 0) {
			return this.getSecondaryGamesFromServer(value).pipe(
				map(res => { this.secondaryGames = res; return this.secondaryGames; }));
		} else {
			return of(this.secondaryGames);
		}
	}

	getSecondaryGamesFromServer(value?: any[]) {
		return this.get(ServiceAction.GAME_SECONDARYGAMES, value);
	}

	getDefaultGames(value?: any[]) {
		if (this.defaultGames.length === 0) {
			return this.getDefaultGamesFromServer(value).pipe(
				map(res => { this.defaultGames = res; return this.defaultGames; }));
		} else {
			return of(this.defaultGames);
		}
	}

	getDefaultGamesFromServer(value?: any[]) {
		return this.get(ServiceAction.GAME_DEFAULTGAMES, value);
	}

	/**
	 * Gets GAMEGROUPS by user tenant IDs in token
	 */
	getGames(): Observable<GamesData[]> {
		return this.get(ServiceAction.AWARDTICKETS_GETGAMESDATA, undefined, undefined);
	}

	/**
	 * Used only for Wallet until call in Wallet BE is updated
	 */
	getGamesData(tenantID: number, activeOnly: boolean = true): Observable<GamesData[]> {
		const gamesParams = new HttpParams().set('tenantID', tenantID.toString())
			.set('activeOnly', activeOnly.toString());
		return this.get(ServiceAction.AWARDTICKETS_GETGAMESDATA, undefined, gamesParams);
	}

	getGameGroupByID(value?: any[]): Observable<GameGroup> {
		return this.get(ServiceAction.GAMEGROUP_GAMEGROUPBYID, value);
	}

	getCurrentGameRuleBoardsByGroup(gameGroupID: number): Observable<Board[]> {
		return this.get(ServiceAction.GAMEGROUP_CURRENT_GAMEGROUP_RULE_BOARDS, [gameGroupID]);
	}
}
