import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AppLayoutService } from './app-layout.service';

import { ComponentHostDirective } from '../../component-host';
import { ChangePasswordService } from '../../change-password';
import { ReconcileDialogService } from 'src/app/retail-agent/shared/services/reconcile-dialog.service';

@Component({
	selector: 'he-secure-content',
	styleUrls: [
		'./secure-content.component.scss'
	],
	templateUrl: './secure-content.component.html'
})

export class SecureContentComponent implements OnInit, AfterViewInit {
	layoutStatic = true;
	mobileMenuActive = false;
	layoutHorizontal = false;
	isRTL = false;

	@ViewChild('overlayHost', { static: false, read: ComponentHostDirective }) overlayHost: ComponentHostDirective;

	constructor(
		private appLayoutService: AppLayoutService,
		private changePasswordService: ChangePasswordService
	) { }

	ngOnInit() {
		this.appLayoutService.menuActiveSubject.subscribe(res => {
			this.mobileMenuActive = res;
		});

		this.appLayoutService.toggleMenuLayoutClick.subscribe(isStatic => {
			this.layoutStatic = isStatic;
		});
		this.layoutStatic = this.appLayoutService.isMenuStatic;
		this.layoutHorizontal = this.appLayoutService.isMenuHorizontal;
	}

	ngAfterViewInit() {
		this.changePasswordService.overlayHost = this.overlayHost;		

		setTimeout(() => {
			const requiresPassChange = localStorage.getItem('requires_pass_change');
			if (requiresPassChange === 'true' || requiresPassChange === undefined || requiresPassChange === null || requiresPassChange === '') {
				this.changePasswordService.showChangePasswordDialog();
			}
		}, 1000);
	}

	onWrapperClick() {
		if (!this.appLayoutService.menuClick && !this.appLayoutService.menuButtonClick) {
			this.mobileMenuActive = false;
		}

		this.appLayoutService.menuClick = false;
		this.appLayoutService.menuButtonClick = false;
	}

	onMaskClick(event: Event) {
		this.mobileMenuActive = !this.mobileMenuActive;
		this.appLayoutService.menuActiveSubject.next(this.mobileMenuActive);
	}
}
