import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
var ActionPermissionsService = /** @class */ (function (_super) {
    tslib_1.__extends(ActionPermissionsService, _super);
    function ActionPermissionsService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.ACTION_PERMISSIONS_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    ActionPermissionsService.prototype.getActions = function () {
        return this.get();
    };
    return ActionPermissionsService;
}(BaseService));
export { ActionPermissionsService };
