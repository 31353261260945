import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceHeadersConfig } from '../../shared/utilities/service-utilities/service-headers.config';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
import { ResponseBodyType } from '../../shared/enums/response-body-type.enum';
import { ServiceUrlsUtility } from '../../shared/utilities/service-utilities/service-urls.utility';
import { ServiceHeadersUtility } from '../../shared/utilities/service-utilities/service-headers.utility';
import { ServiceRequestBodyUtility } from '../../shared/utilities/service-utilities/service-request-body.utility';
import { ServiceError } from '../../shared/models/general/service-error.model';

/**
 * Base Service class used to handle HTTP requests to the server
 */
export abstract class BaseService {
	/**
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 * @param controller Contains the 'controller' part of the request URL
	 * @param headersConfig Object containing the headers configuration for the HTTP requests, such as Content-Type and Authorization
	 */
	constructor(
		protected http: HttpClient,
		protected controller: ServiceController,
		protected baseServiceURL: string,
		protected headersConfig: ServiceHeadersConfig = new ServiceHeadersConfig()
	) { }

	/**
	 * Function to make an HTTP GET request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @returns An Observable of the data returned from the server
	 */
	public get<T>(action?: ServiceAction, value?: any[], searchParams?: HttpParams,
		responseBodyType: ResponseBodyType = ResponseBodyType.JSON): Observable<any> {

		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		options.responseType = this.setResponseType(responseBodyType);

		return this.http.get<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);

		// return this.http.get(serviceUrl, options)
		// 	.map(res => this.extractData(res, responseBodyType))
		// 	.catch(this.handleError);
	}

	/**
	 * Function to make an HTTP POST request
	 * @param action Contains the 'action' part of the request URL
	 * @param postData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @returns An Observable of the data returned from the server
	 */
	public post<T>(action?: ServiceAction, postData?: any, searchParams?: HttpParams): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action);

		const body = ServiceRequestBodyUtility.getRequestBody(postData, this.headersConfig.contentType);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.post<T>(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	public voidPost(action?: ServiceAction, postData?: any, searchParams?: HttpParams): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action);

		const body = ServiceRequestBodyUtility.getRequestBody(postData, this.headersConfig.contentType);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		const observable = this.http.post(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);

		return observable;
	}

	/**
	 * Function to make an HTTP PUT request
	 * @param action Contains the 'action' part of the request URL
	 * @param editData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @returns An Observable of the data returned from the server
	 */
	public edit<T>(action?: ServiceAction, editData?: any, searchParams?: HttpParams): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action);

		const body = JSON.stringify(editData);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.put<T>(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function to make an HTTP PATCH request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param patchData Object containing the data to be sent to the server
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @returns An Observable of the data returned from the server
	 */
	public patch<T>(action?: ServiceAction, value?: any[], patchData?: any, searchParams?: HttpParams): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action, value);

		const body = JSON.stringify(patchData);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		return this.http.patch<T>(serviceUrl, body, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function to make an HTTP DELETE request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @returns An Observable of boolean signifying whether the delete was successful or not
	 */
	public delete<T>(action?: ServiceAction, value?: any[]): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		return this.http.delete<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function to make an HTTP DELETE request
	 * @param action Contains the 'action' part of the request URL
	 * @param value Array containing the value/s to be appended to the request URL
	 * @param searchParams Values to be passed as the query string of the request URL
	 * @returns An Observable of any signifying whether the delete was successful or not and any data it might return
	 */
	public deleteWithReturn<T>(action?: ServiceAction, value?: any[]): Observable<any> {
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, this.controller, action, value);

		const headers: HttpHeaders = ServiceHeadersUtility.httpHeaders(this.headersConfig);

		const options: any = {
			headers: headers
		};

		return this.http.delete<T>(serviceUrl, options)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Function called to read the server's response
	 * @returns The serialised data
	 */
	// protected extractData(res: Response, responseBodyType: ResponseBodyType = ResponseBodyType.JSON): any {
	// 	if (res.status < 200 || res.status >= 300) {
	// 		throw new Error('Bad response status ' + res.status);
	// 	}

	// 	let body;
	// 	switch (responseBodyType) {
	// 		case ResponseBodyType.Text:
	// 			body = res.text();
	// 			break;
	// 		case ResponseBodyType.Blob:
	// 			body = res.blob();
	// 			break;
	// 		case ResponseBodyType.JSON:
	// 		default:
	// 			body = res.json();
	// 			break;
	// 	}

	// 	let mappedData = this.mapData(body);
	// 	return mappedData;
	// }

	/**
	 * Function called to read the server's response when no return data is expected
	 * @returns A boolean signifying whether the request was successful or not
	 */
	// protected voidExtractData(res: Response): boolean {
	// 	if (res.status < 200 || res.status >= 300) {
	// 		return false;
	// 	}

	// 	return true;
	// }

	/**
	 * Function called to serialise the returned data from the server
	 * @returns The serialised data
	 */
	// protected abstract mapData(data: any): any;

	/**
	 * Function called to handle unexpected errors
	 */
	protected handleError(serviceErrors: HttpErrorResponse) {
		let errors: ServiceError[] = serviceErrors.error as ServiceError[];
		if (errors !== null && errors !== undefined) {
			errors.forEach((item) => {
				item.status = serviceErrors.status;
			});
		} else {
			errors = [];
			errors.push(new ServiceError(serviceErrors.status, serviceErrors.statusText, 0, undefined));
		}

		return _throw(errors);
	}

	/**
	 * Function to set responseType
	 */
	private setResponseType(responseBodyType: ResponseBodyType): string {
		switch (responseBodyType) {
			case ResponseBodyType.Text:
				return 'text';
			case ResponseBodyType.Blob:
				return 'blob';
			case ResponseBodyType.JSON:
			default:
				return 'json';
		}
	}
}
