import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { of } from 'rxjs';
import { LoginService } from '../../helio-core-services';

@Injectable()
export class AppGuard implements CanActivate {
	constructor(private router: Router, private loginService: LoginService) { }

	canActivate(next: ActivatedRouteSnapshot) {
		if (!this.loginService.isLoggedIn && this.loginService.isInactivityDateLimitExpired) {
			return this.logoutAndRedirect();
		}

		const hasPageRigts = this.checkPageRights(next);

		if (!hasPageRigts) {
			return this.redirectToNoAccessRights();
		}

		return of(true);
	}

	private checkPageRights(next: ActivatedRouteSnapshot): boolean {
		const urlSegments = this.getURLSegments(next);

		// FOR DEV ONLY ---
		if(urlSegments.includes("my-profile")) return true;
		// ----

		// get page rights object from token/local storage
		// loop in object to find current page
		const localStorageList = JSON.parse(JSON.parse(localStorage.getItem('access_rights')).Pages);
		let pageChildren = [];

		for (let i = 0; i < urlSegments.length; i++) {
			const urlSegment = urlSegments[i];

			const listToCheck = (i === 0) ? localStorageList : pageChildren;
			const page = listToCheck.find(temp => {
				return temp.Name === urlSegment;
			});

			if (page === undefined) {
				return false;
			}

			pageChildren = page.ChildPages;
		}

		return true;
	}

	private getURLSegments(next: ActivatedRouteSnapshot): string[] {
		const tempURLSegments: string[] = [];
		const pathFromRoot: ActivatedRouteSnapshot[] = next.pathFromRoot;

		pathFromRoot.forEach(temp => {
			if (temp.url.length > 0) {
				tempURLSegments.push(temp.url[0].path);
			}
		});

		return tempURLSegments;
	}

	private redirectToNoAccessRights() {
		const queryParams = { redirectURL: this.router.url };
		this.router.navigate(['no-access-rights'], { queryParams: queryParams });

		return of(false);
	}

	private logoutAndRedirect() {
		this.loginService.logoutAndRedirect();

		return of(false);
	}
}
