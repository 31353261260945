// "import setValue from 'set-value'"; resulted in an error, so require is being used
// Most probably this is related to how the module is exported (https://stackoverflow.com/a/39415662/2312637)
const setValue = require('set-value');

/**
 * Utility Class to access object properties using dot notation in a string.
 * Class contains a get value and set value.
 * Set value function is dependant on a third party module 'set-value'
 */
export class ObjPropsStringDotNotationUtility {
	/**
	 * Function to get value of a property
	 * @param data Object to get value from
	 * @param objProperty string representing the property to access
	 *
	 * @example
	 * let person = {
	 * 	name: 'Peter',
	 * 	surname: 'Pan',
	 * 	contact: {
	 * 	 address: '1, Neverland Street, Neverland',
	 * 	 phone: 99999999
	 * 	}
	 * };
	 *
	 * // returns phone number
	 * getObjValue(person, 'contact.phone');
	 */
	public static getObjValue(data: any, objProperty: string): any {
		const properties = objProperty.split('.');
		let tempData = data;

		for (const tempProperty of properties) {
			const tempValue = tempData[tempProperty];

			if (tempValue !== undefined && tempValue !== null) {
				tempData = tempValue;
			} else {
				tempData = undefined;
				break;
			}
		}

		return tempData;
	}

	/**
	 * Function to set value of a property
	 * @param data Object to set value in
	 * @param objProperty string representing the property to update
	 * @param newValue Updated value
	 *
	 * @example
	 * let person = {
	 * 	name: 'Peter',
	 * 	surname: 'Pan',
	 * 	contact: {
	 * 	 address: '1, Neverland Street, Neverland',
	 * 	 phone: 99999999
	 * 	}
	 * };
	 *
	 * // updates phone number
	 * setObjValue(person, 'contact.phone', 123456798);
	 */
	public static setObjValue(data: any, objProperty: string, newValue: any): void {
		setValue(data, objProperty, newValue);
	}
}
