/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/button/button";
import * as i2 from "../shared/components/app-title/app-title.component.ngfactory";
import * as i3 from "../shared/components/app-title/app-title.component";
import * as i4 from "@angular/common";
import * as i5 from "./app-topbar.component";
import * as i6 from "@angular/router";
import * as i7 from "../helio-core-services/services/login.service";
import * as i8 from "../helio-core-services/services/user-rights.service";
import * as i9 from "../layout/secure/app-layout.service";
var styles_AppTopBarComponent = [];
var RenderType_AppTopBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppTopBarComponent, data: {} });
export { RenderType_AppTopBarComponent as RenderType_AppTopBarComponent };
function View_AppTopBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["style", "color: #ffffff;font-size: 14px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" \n\t\t\t\tWelcome \n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "flat"], ["pButton", ""], ["style", "font-weight: 600;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 4341760, null, 0, i1.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.loggedInUser.username, ""); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppTopBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 25, "div", [["class", "layout-topbar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["class", "menu-btn"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMenuButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\uE5D2"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "he-app-title", [["class", "mobile-logo"]], null, null, null, i2.View_AppTitleComponent_0, i2.RenderType_AppTitleComponent)), i0.ɵdid(11, 49152, null, 0, i3.AppTitleComponent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(14, 0, null, null, 10, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(16, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppTopBarComponent_1)), i0.ɵdid(19, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(21, 0, null, null, 1, "button", [["class", "flat"], ["label", "Logout"], ["pButton", ""], ["style", "font-weight: 600;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(22, 4341760, null, 0, i1.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loggedInUser; _ck(_v, 19, 0, currVal_0); var currVal_1 = "Logout"; _ck(_v, 22, 0, currVal_1); }, null); }
export function View_AppTopBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "he-topbar", [], null, null, null, View_AppTopBarComponent_0, RenderType_AppTopBarComponent)), i0.ɵdid(1, 245760, null, 0, i5.AppTopBarComponent, [i6.Router, i7.LoginService, i8.UsersService, i9.AppLayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppTopBarComponentNgFactory = i0.ɵccf("he-topbar", i5.AppTopBarComponent, View_AppTopBarComponent_Host_0, {}, {}, []);
export { AppTopBarComponentNgFactory as AppTopBarComponentNgFactory };
