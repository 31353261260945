import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { of, Subscription } from 'rxjs';

import { map, delay, catchError } from 'rxjs/operators';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';
import { WindowEventsService } from './window-events.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { Login } from '../../shared/models/user/login.model';
import { ServiceHeadersConfig } from '../../shared/utilities/service-utilities/service-headers.config';

/**
 * Service class used to handle HTTP requests related to Login
 */
@Injectable()
export class LoginService extends BaseService {
	tokenExpiresDate: Date;

	isUserActive = false;

	// private _isLoggedIn = false;
	private sessionExpiredHandlerSubscription: Subscription;

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 */
	constructor(
		protected http: HttpClient,
		private appConfigService: AppConfigService,
		private windowEventsService: WindowEventsService,
		private router: Router
	) {
		super(http, ServiceController.LOGIN_CONTROLLER,
			appConfigService.authBaseURL,
			new ServiceHeadersConfig(true, 'application/x-www-form-urlencoded'));

		this.isUserActiveHandler();
	}

	get isLoggedIn(): boolean {
		if (localStorage.getItem('access_token') !== null) {
			const now = new Date();
			this.tokenExpiresDate = new Date(localStorage.getItem('token_expires_date'));

			if (now < this.tokenExpiresDate) {
				localStorage.setItem('log_status', 'true');
				return true;
			}
		}

		localStorage.setItem('log_status', 'false');
		return false;
	}

	get isInactivityDateLimitExpired(): boolean {
		const now = new Date();
		const inactiveDateLimit = new Date(localStorage.getItem('inactivity_date_limit'));

		return (now >= inactiveDateLimit);
	}

	get canRefreshToken(): boolean {
		const refreshToken = localStorage.getItem('refresh_token');
		if (refreshToken !== null) {
			const now = new Date();
			const refreshTokenExpires = new Date(localStorage.getItem('refresh_token_expires_date'));

			return (now < refreshTokenExpires);
		}

		return false;
	}

	loginUser(loginData: Login) {
		loginData.client_id = this.appConfigService.tokenClientID;

		return this.post(undefined, loginData)
			.pipe(
				map(res => {
					return this.saveSessionData(res);
				})
			);
	}

	refreshUserToken(autoLogout = true) {
		const data = {
			refresh_token: localStorage.getItem('refresh_token'),
			grant_type: 'refresh_token',
			client_id: this.appConfigService.tokenClientID
		};

		return this.post(undefined, data)
			.pipe(
				map(res => {
					return this.saveSessionData(res);
				}),
				catchError(() => {
					if (autoLogout) {
						this.logoutAndRedirect(true);
					}
					return of(null);
				})
			);
	}

	/**
	 * Not used in this service
	 */
	protected mapData(data: any): any {
		// not needed in this service
	}

	/**
	 * Clears data stored locally to log out
	 */
	logOut(): void {
		// Stop refresh of token
		this.unsubscribeSessionExpiredHandler();

		// Clean up local storage
		localStorage.setItem('log_status', 'false');

		localStorage.removeItem('access_token');
		localStorage.removeItem('token_type');
		localStorage.removeItem('access_rights');
		localStorage.removeItem('logged_in_userID');
		localStorage.removeItem('expires_in');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('token_expires_date');
		localStorage.removeItem('refresh_token_expires_date');
		localStorage.removeItem('inactivity_date_limit');
		localStorage.removeItem('requires_pass_change');
	}

	/**
	 * Function to save the user data in the localStorage after a successful login
	 */
	saveSessionData(data: any): void {
		localStorage.setItem('log_status', 'true');

		localStorage.setItem('access_token', data.access_token);
		localStorage.setItem('token_type', data.token_type);
		localStorage.setItem('expires_in', data.expires_in);
		localStorage.setItem('refresh_token', data.refresh_token);

		const tokenExpires = new Date();
		tokenExpires.setSeconds(tokenExpires.getSeconds() + data.expires_in);
		localStorage.setItem('token_expires_date', tokenExpires.toISOString());

		this.tokenExpiresDate = new Date(tokenExpires.toISOString());

		const refreshTokenExpires = new Date(data['.expires']);
		localStorage.setItem('refresh_token_expires_date', refreshTokenExpires.toISOString());

		// Get the access rights from token
		const encodedString = data.access_token.split('.')[1];
		// Decode it (base64)...
		const decodedString = atob(encodedString);
		// ...and store them locally
		localStorage.setItem('access_rights', decodedString);
		localStorage.setItem('logged_in_userID', JSON.parse(decodedString).Id);
		localStorage.setItem('requires_pass_change', JSON.parse(decodedString).RequiresPasswordChange);
	}

	logoutAndRedirect(isSessionExpired: boolean = false) {
		// get url without query params
		// ref: https://stackoverflow.com/a/46107850
		const urlTree = this.router.parseUrl(this.router.url);

		const key = 'primary';
		const rootChildren = urlTree.root.children[key];

		let urlWithoutParams: string;

		if (rootChildren !== undefined) {
			urlWithoutParams = urlTree.root.children[key].segments.map(it => it.path).join('/');
		}

		if (urlWithoutParams !== 'login') {
			const queryParams = (isSessionExpired) ? { queryParams: { 'sessionExpired': true, 'redirectURL': this.router.url } } : undefined;

			this.logOut();
			this.router.navigate(['/login'], queryParams);
		}
	}

	private sessionExpiredHandler() {
		return of(true)
			.pipe(
				delay(this.tokenExpiresDate),
				map(() => {
					if (this.isUserActive) {
						this.refreshUserToken().subscribe(() => {
							this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
						});
					}
				})
			);
	}

	private isUserActiveHandler() {
		this.windowEventsService.onFocus.subscribe(() => {
			this.isUserActive = true;
			localStorage.setItem('is_user_active', 'true');

			// if token is valid (i.e. not expired)
			if (this.isLoggedIn) {
				if (this.sessionExpiredHandlerSubscription === undefined) {
					this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
				}
			} else {
				if (!this.isInactivityDateLimitExpired) {
					if (this.canRefreshToken) {
						this.refreshUserToken().subscribe(() => {
							this.unsubscribeSessionExpiredHandler();

							this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
						});
					} else {
						this.logoutAndRedirect(true);
					}
				} else {
					this.logoutAndRedirect(true);
				}
			}
		});

		this.windowEventsService.onBlur.subscribe(() => {
			this.isUserActive = false;
			localStorage.setItem('is_user_active', 'false');

			this.unsubscribeSessionExpiredHandler();

			if (this.isLoggedIn) {
				const now = new Date();
				const inactiveDateLimit = new Date(now.setSeconds(now.getSeconds() + this.appConfigService.inactiveTimeLimit));

				localStorage.setItem('inactivity_date_limit', inactiveDateLimit.toISOString());
			}
		});
	}

	private unsubscribeSessionExpiredHandler() {
		if (this.sessionExpiredHandlerSubscription !== undefined) {
			this.sessionExpiredHandlerSubscription.unsubscribe();
		}
	}
}
