import { HttpParams } from '@angular/common/http';

export class HttpParamsUtility {
	public static updateAdvanceSearchFilter(searchParams: HttpParams, newData: string): HttpParams {
		if (searchParams === undefined) {
			searchParams = new HttpParams();
		}

		const hasFilter = searchParams.has('$filter');
		const updatedParam = (hasFilter) ? `(${searchParams.get('$filter')}) AND ${newData}` : newData;

		return searchParams.set('$filter', updatedParam);
	}
}
