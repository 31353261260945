export enum SearchOperator {
	EQUAL,
	NOT_EQUAL,
	LESS_THAN,
	LESS_THAN_OR_EQUAL,
	GREATER_THAN,
	GREATER_THAN_OR_EQUAL,
	// CONTAINS, - deprecated, replaced by LIKE
	BETWEEN,
	LIKE
}
