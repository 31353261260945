/**
 * Class used to set the request headers of an HTTP request
 */
export class ServiceHeadersConfig {
	/**
	 * @param isAnonymous boolean signifying whether the request requires authorization to access a particular service call
	 *
	 * When true Authorization header is set
	 * @param contentType string to set the Content-Type of the request to be made
	 */
	constructor(public isAnonymous: boolean = false, public contentType: string = 'application/json') { }
}
