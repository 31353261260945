import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../helio-core-services';

@Component({
	selector: 'he-home',
	styleUrls: [
		'./home.component.css'
	],
	templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
	constructor(private breadcumbService: BreadcrumbService) { }

	ngOnInit() {
		this.breadcumbService.setItems([]);
	}
}
