import * as fileSaver from 'file-saver';

export class FileDownloadUtility {
	/**
	 * Function to download CSV File
	 * @param responseData File contents retrieved from server
	 * @param filename Filename of the downloaded file - do not include extension
	 */
	public static downloadCsv(responseData: any, filename: string): void {
		const byteCharacters = responseData as string;
		const byteNumbers = [byteCharacters.length];

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: 'text/csv' });
		fileSaver.saveAs(blob, `${filename}.csv`);
	}
}
