import { Component, OnInit } from '@angular/core';

import { MenuItem, Message } from 'primeng/api';
import { ColumnType, DataTableAction, DataTableActionCallback } from '../shared';

@Component({
	selector: 'he-kitchen-sink',
	styleUrls: [
		'./kitchen-sink.component.scss'
	],
	templateUrl: './kitchen-sink.component.html'
})

export class KitchenSinkComponent implements OnInit {
	msg: Array<Message> = [];
	cols: any = [];
	dataTableV2Cols: any[] = [];
	dataTableV2InnerCols: any[] = [];
	dataTableV2Actions: DataTableAction[];
	dataTableV2Actions2: DataTableAction[];

	personsList: Array<any> = [
		{
			id: 1, name: 'John', surname: 'Doe', dob: new Date(), active: true,
			cars: [
				{ id: 1, model: 'BMW', colour: 'Red' },
				{ id: 2, model: 'Audi', colour: 'Orange' }
			]
		},
		{
			id: 2, name: 'Jane', surname: 'Doe', dob: new Date(), active: false, cars: [
				{ id: 3, model: 'KIA', colour: 'Yellow' }
			]
		},
		{
			id: 3, name: 'Peter', surname: 'Pan', dob: new Date(), active: true, cars: [
				{ id: 4, model: 'Toyota', colour: 'Green' },
				{ id: 5, model: 'Mazda', colour: 'Blue' }
			]
		},
		{
			id: 4, name: 'Wendy', surname: 'Darling', dob: new Date(), active: false, cars: [
				{ id: 6, model: 'Ford', colour: 'Silver' },
				{ id: 7, model: 'VW', colour: 'Black' }
			]
		}
	];

	selectedPersons: Array<any> = [];

	actionsMenu: MenuItem[] = [
		{
			label: 'Edit'
		}
	];

	ngOnInit() {
		this.cols = [
			{ field: 'id', header: 'ID' },
			{ field: 'name', header: 'Name' },
			{ field: 'surname', header: 'Surname' }
		];

		this.dataTableV2Cols = [
			{ field: 'id', header: 'ID', columnType: ColumnType.String },
			{ field: 'name', header: 'Name', columnType: ColumnType.String },
			{ field: 'surname', header: 'Surname', columnType: ColumnType.String },
			{ field: 'dob', header: 'DOB', columnType: ColumnType.Date, sortable: false },
			{ field: 'active', header: 'Active', columnType: ColumnType.TrueFalse }
		];

		this.dataTableV2InnerCols = [
			{ field: 'id', header: 'ID', columnType: ColumnType.String },
			{ field: 'model', header: 'Model', columnType: ColumnType.String },
			{ field: 'colour', header: 'Colour', columnType: ColumnType.String }
		];

		this.dataTableV2Actions = [
			{
				menuItem: {
					label: 'Edit',
					icon: 'ui-icon-mode-edit'
				},
				callback: (callbackObj) => {
					console.log(callbackObj.data);
					const test = callbackObj.data.map(x => x.id);
					console.log(test);
				}
			}, {
				menuItem: {
					label: 'Row Only Edit',
					icon: 'ui-icon-mode-edit'
				},
				callback: (callbackObj) => console.log(callbackObj),
				isBulkAction: false
			}, {
				menuItem: {
					label: 'Bulk Only Edit',
					icon: 'ui-icon-mode-edit'
				},
				callback: (callbackObj) => console.log(callbackObj),
				isRowAction: false
			}
		];

		this.dataTableV2Actions2 = [
			{
				menuItem: { label: 'Trying' },
				callback: (callbackObj) => console.log(callbackObj)
			}
		];
	}

	rowChangeTest(data: any) {
		console.log(data);
	}

	onRowSelect(event: any) {
		this.msg = [];
		this.msg.push({ severity: 'success', summary: 'Row Selected', detail: event.data.name + ' ' + event.data.surname });
	}

	onRowUnselect(event: any) {
		this.msg = [];
		this.msg.push({ severity: 'warn', summary: 'Row Unselected', detail: event.data.name + ' ' + event.data.surname });
	}
}
