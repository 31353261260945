import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
var GameAdministrationService = /** @class */ (function (_super) {
    tslib_1.__extends(GameAdministrationService, _super);
    function GameAdministrationService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.GAMEADMINISTRATION_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        _this.allGames = [];
        _this.defaultGames = [];
        _this.secondaryGames = [];
        return _this;
    }
    GameAdministrationService.prototype.getAllGames = function () {
        var _this = this;
        if (this.allGames.length === 0) {
            return this.getAllGamesFromServer().pipe(map(function (res) { _this.allGames = res; return _this.allGames; }));
        }
        else {
            return of(this.allGames);
        }
    };
    GameAdministrationService.prototype.getAllGamesFromServer = function () {
        return this.get(ServiceAction.GENERIC_GAMES);
    };
    GameAdministrationService.prototype.getSecondaryGames = function (value) {
        var _this = this;
        if (this.secondaryGames.length === 0) {
            return this.getSecondaryGamesFromServer(value).pipe(map(function (res) { _this.secondaryGames = res; return _this.secondaryGames; }));
        }
        else {
            return of(this.secondaryGames);
        }
    };
    GameAdministrationService.prototype.getSecondaryGamesFromServer = function (value) {
        return this.get(ServiceAction.GAME_SECONDARYGAMES, value);
    };
    GameAdministrationService.prototype.getDefaultGames = function (value) {
        var _this = this;
        if (this.defaultGames.length === 0) {
            return this.getDefaultGamesFromServer(value).pipe(map(function (res) { _this.defaultGames = res; return _this.defaultGames; }));
        }
        else {
            return of(this.defaultGames);
        }
    };
    GameAdministrationService.prototype.getDefaultGamesFromServer = function (value) {
        return this.get(ServiceAction.GAME_DEFAULTGAMES, value);
    };
    /**
     * Gets GAMEGROUPS by user tenant IDs in token
     */
    GameAdministrationService.prototype.getGames = function () {
        return this.get(ServiceAction.AWARDTICKETS_GETGAMESDATA, undefined, undefined);
    };
    /**
     * Used only for Wallet until call in Wallet BE is updated
     */
    GameAdministrationService.prototype.getGamesData = function (tenantID, activeOnly) {
        if (activeOnly === void 0) { activeOnly = true; }
        var gamesParams = new HttpParams().set('tenantID', tenantID.toString())
            .set('activeOnly', activeOnly.toString());
        return this.get(ServiceAction.AWARDTICKETS_GETGAMESDATA, undefined, gamesParams);
    };
    GameAdministrationService.prototype.getGameGroupByID = function (value) {
        return this.get(ServiceAction.GAMEGROUP_GAMEGROUPBYID, value);
    };
    GameAdministrationService.prototype.getCurrentGameRuleBoardsByGroup = function (gameGroupID) {
        return this.get(ServiceAction.GAMEGROUP_CURRENT_GAMEGROUP_RULE_BOARDS, [gameGroupID]);
    };
    return GameAdministrationService;
}(BaseService));
export { GameAdministrationService };
