import { Injectable } from '@angular/core';
import { ServiceError, ToastMessageType } from '../../shared/models/general';
import { ToastDisplayService } from './toast-display.service';

@Injectable()
export class BoErrorHandlerService {
	constructor(private toastDisplayService: ToastDisplayService) { }

	handleError(error: ServiceError[] | Error, errorMessage?: string, callName?: string): void {
		const summaryWording: string = callName === undefined ? 'Error Message' : callName;

		if (error === undefined) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: summaryWording,
				description: errorMessage === undefined ? 'An error has occurred. Please contact support.' : errorMessage
			});
		} else if (error[0] instanceof Object) {
			let alertErrorMessage = (errorMessage === undefined) ? error[0].description : errorMessage;
			if (error[0].status === 401) {
				alertErrorMessage = 'You are not authorised to perform this action.';
			}

			if (error[0].status === 500) {
				alertErrorMessage = 'An error has occurred. Please contact support.';
			}

			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: summaryWording,
				description: alertErrorMessage
			});
		}
	}
}
