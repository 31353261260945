import { Injectable } from '@angular/core';
import { DataTableLazyLoadEvent, SearchField } from 'src/app/shared';

@Injectable()
export class AdvancedSearchService {
    public fields: Map<string, SearchField[]> = new Map();
    public showAdvancedSearch: Map<string, boolean> = new Map();
    constructor() { }

    shouldShowAdvancedSearch(path: string) {
        return !!this.showAdvancedSearch.get(path);
    }

    setShowAdvancedSearch(path: string, value: boolean) {
        this.showAdvancedSearch.set(path, value);
    }
}