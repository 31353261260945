import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import {
	atLeastOneLowerCaseLetter, atLeastOneUpperCaseLetter, atLeastOneNumber,
	atLeastOneSymbol, containsUsername, sameString, isNotSameString
} from '../shared/validators/validators';
import { ValidationService } from '../shared/components/form-messages/validation.service';
import { ChangePasswordService } from './change-password.service';
import { AppLoaderService } from '../helio-core-services/services/app-loader.service';
import { UsersService } from '../helio-core-services/services/user-rights.service';
import { BoErrorHandlerService } from '../helio-core-services/services/bo-error-handler.service';
import { ChangePassword } from '../shared/models/user/change-password.model';
import { ToastDisplayService, LoginService } from '../helio-core-services';
import { ToastMessageType } from '../shared/models';
import { Router } from '@angular/router';

@Component({
	selector: 'he-change-password',
	styleUrls: [
		'./change-password.component.scss'
	],
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
	@Input() isModal = true;
	@Input() headerMessage = "Your Password must be changed";
	changePasswordForm: FormGroup;
	errors: any;
	confirmPasswordError: any;
	information: string;
	displayInfo = false;

	private userID: number;
	private refreshTokenSuccessful = false;

	constructor(
		private formBuilder: FormBuilder,
		private appLoaderService: AppLoaderService,
		private userService: UsersService,
		private loginService: LoginService,
		private changePasswordService: ChangePasswordService,
		private boErrorHandlerService: BoErrorHandlerService,
		private router: Router
	) { }

	ngOnInit() {
		this.userID = +localStorage.getItem('logged_in_userID');

		this.initForm();
	}

	closeInfoAlert() {
		this.displayInfo = false;
		if (this.refreshTokenSuccessful) {
			this.changePasswordService.destroyChangePasswordDialog();
		} else {
			this.loginService.logOut();
			this.router.navigate(['login']);
		}
	}

	saveNewPassword() {
		this.appLoaderService.isLoading = true;
		const newPasswordData: ChangePassword = {
			userID: this.userID,
			oldPassword: this.changePasswordForm.get('oldPassword').value,
			newPassword: this.changePasswordForm.get('newPassword').value
		};

		this.userService.changePassword(newPasswordData)
			.subscribe(res => {
				this.refreshToken();
			}, error => {
				this.appLoaderService.isLoading = false;
				this.boErrorHandlerService.handleError(error, undefined, 'Changing Password');
			});
	}

	private refreshToken() {
		this.loginService.refreshUserToken(false).subscribe(() => {
			this.appLoaderService.isLoading = false;
			this.information = 'Your Password has been changed successfully!';
			this.displayInfo = true;
			this.refreshTokenSuccessful = true;
		}, error => {
			this.appLoaderService.isLoading = false;
			this.information =
				'Your Password has been changed successfully! You will now be logged out and you may login again with your new Password.';
			this.displayInfo = true;
			this.refreshTokenSuccessful = false;
		});
	}

	private initForm() {
		const currentUsername = localStorage.getItem('user_name');

		this.changePasswordForm = this.formBuilder.group({
			oldPassword: ['', Validators.required],
			newPassword: [''],
			confirmNewPassword: ['']
		});

		// set new password validtors
		this.changePasswordForm.get('newPassword').setValidators([
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(128),
			atLeastOneLowerCaseLetter(),
			atLeastOneUpperCaseLetter(),
			atLeastOneNumber(),
			atLeastOneSymbol(),
			(control: AbstractControl) => containsUsername(currentUsername)(control),
			(control: AbstractControl) => sameString(this.changePasswordForm.get('oldPassword').value)(control)
		]);

		console.log("THE CONTROL:", this.changePasswordForm.get('confirmNewPassword'));
		this.changePasswordForm.get('confirmNewPassword').setValidators([
			Validators.required,
			(control: AbstractControl) => isNotSameString(this.changePasswordForm.get('newPassword').value)(control)
		]);

		this.changePasswordForm.get('newPassword').valueChanges
			.subscribe(() => {
				const validatorErrors = this.changePasswordForm.get('newPassword').errors;

				if (validatorErrors !== undefined && validatorErrors !== null) {
					const objectKeys = Object.keys(validatorErrors);
					for (const tempValidator of objectKeys) {
						const error = ValidationService.getValidatorErrorMessage(tempValidator, validatorErrors[tempValidator]);
						if (error !== undefined) {
							this.errors = error;
						}
					}
				}
			});

		this.changePasswordForm.get('confirmNewPassword').valueChanges
			.subscribe(() => {
				const validatorErrors = this.changePasswordForm.get('confirmNewPassword').errors;

				if (validatorErrors !== undefined && validatorErrors !== null) {
					const objectKeys = Object.keys(validatorErrors);
					for (const tempValidator of objectKeys) {
						const error = ValidationService.getValidatorErrorMessage(tempValidator, validatorErrors[tempValidator]);
						if (error !== undefined) {
							this.confirmPasswordError = error;
						}
					}
				}
			});
	}
}
