import * as tslib_1 from "tslib";
/**
 * Utility class to set the request URL
 */
var ServiceUrlsUtility = /** @class */ (function () {
    function ServiceUrlsUtility() {
    }
    /**
     * Function to generate the request URL
     * @params controller The 'controller' part of the URL
     * @params action The 'action' part of the URL
     * @params value Array containing the value/s to be appeneded to the URL
     * @return The generated URL string
     */
    ServiceUrlsUtility.getUrl = function (baseServiceURL, controller, action, value) {
        var e_1, _a;
        var appConfig = JSON.parse(localStorage.getItem('helioEngineAppConfig'));
        // replace subdomain in URL
        if (appConfig.useSubdomain && baseServiceURL.indexOf('*.') !== -1) {
            baseServiceURL = this.replaceSubdomain(baseServiceURL);
        }
        // appending controller to service url
        var url = baseServiceURL + controller;
        // if defined, append action
        if (action !== undefined) {
            url += action;
        }
        // if defined, append value
        if (value !== undefined) {
            // checking if there's a trailing slash before adding "/[value]"
            // so that we avoid having a URL with "//[value]"
            // Reference: http://stackoverflow.com/a/3884711/2312637
            url += (url.slice(-1) === '/') ? '' : '/';
            try {
                for (var value_1 = tslib_1.__values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                    var tempVal = value_1_1.value;
                    url += tempVal + '/';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (value_1_1 && !value_1_1.done && (_a = value_1.return)) _a.call(value_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return url;
    };
    // http://stackoverflow.com/a/1714899/2312637
    ServiceUrlsUtility.getObjectSearchParams = function (httpParams, object, prefix) {
        // let searchParams: HttpParams = new HttpParams();
        for (var prop in object) {
            if (object.hasOwnProperty(prop)) {
                var key = void 0;
                var value = object[prop];
                if (prefix) {
                    key = prefix + "[" + prop + "]";
                }
                else {
                    key = prop;
                }
                if (value !== null && typeof value === 'object') {
                    httpParams = this.getObjectSearchParams(httpParams, value, key);
                }
                else {
                    httpParams = httpParams.set(key, value);
                }
            }
        }
        return httpParams;
    };
    ServiceUrlsUtility.replaceSubdomain = function (baseServiceURL) {
        var subdomain = localStorage.getItem('subdomain');
        if (subdomain === null) {
            return baseServiceURL.replace('*.', '');
        }
        return baseServiceURL.replace('*', subdomain);
    };
    return ServiceUrlsUtility;
}());
export { ServiceUrlsUtility };
