import { Subject } from 'rxjs';
var AppLoaderService = /** @class */ (function () {
    function AppLoaderService() {
        this.loadChangeSubject = new Subject();
        this._isLoading = false;
        this.loadChange = this.loadChangeSubject.asObservable();
    }
    Object.defineProperty(AppLoaderService.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        set: function (setting) {
            this._isLoading = setting;
            this.loadChangeSubject.next(this._isLoading);
        },
        enumerable: true,
        configurable: true
    });
    return AppLoaderService;
}());
export { AppLoaderService };
