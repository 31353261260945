import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';

import { ServiceController } from '../../shared/utilities/service-utilities';
import { DataTableServerResponse } from '../../shared/components/data-table-v3';
import { Group } from '../../shared/models/group';

/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class GroupsService extends BaseService {

	public totalElementCount: number;

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 */
	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.GROUPS_CONTROLLER, appConfigService.serviceBaseURL);
	}

	createGroup(group: Group) {
		return this.post(undefined, group);
	}

	editGroup(group: Group) {
		return this.edit(undefined, group);
	}

	getGroups(searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.get<DataTableServerResponse>(undefined, undefined, searchParams);
	}
}
