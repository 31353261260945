<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': sidebarActive}"
	(click)="onSidebarClick($event)" (mouseover)="mouseOverLeaveHandler(true)" (mouseleave)="mouseOverLeaveHandler(false)">
	
	<div class="sidebar-logo">
		<he-app-title [@fadeLogo]="isMenuStatic || sidebarActive"></he-app-title>
		<!-- <he-app-title [@fadeLogo]="isMenuStatic"></he-app-title> -->
	</div>
	<div id="scroll-panel-wrapper">
		<p-scrollPanel [style]="{ height: '100%', 'overflow-x': 'hidden' }">
			<ul app-submenu [item]="menuItems" root="true" class="layout-menu" visible="true" [reset]="reset"></ul>
		</p-scrollPanel>
	</div>
	<div id="menu-bottom" (click)="toggleMenu()">
		<div>
			<span *ngIf="isMenuStatic">Collapse Menu</span>
			<span *ngIf="!isMenuStatic">Pin Menu</span>
			<i [ngClass]="{'ui-icon-chevron-left': isMenuStatic, 'ui-icon-chevron-right': !isMenuStatic}"></i>
		</div>
	</div>

	<!-- <ul app-submenu [item]="menuItems" root="true" class="layout-menu" visible="true" [reset]="reset"></ul> -->
	<!-- <he-sub-menu></he-sub-menu> -->
</div>

