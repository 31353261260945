import {AppConfigService} from './helio-core-services';
import {environment} from '../environments/environment';
import {registerLocaleData} from '@angular/common';
import {AppLangService} from './app-lang.service';

export function appInitLoader(appLangService: AppLangService, appConfigService: AppConfigService) {
	return async () => {
		await appConfigLoader(appConfigService);
		await loadDefaultLocale(appLangService);

		return Promise.resolve();
	};
}

function appConfigLoader(appConfigService: AppConfigService) {

	return new Promise<void>(resolve => {
		let serverConfig: string;
		let systemType: string;

		switch (environment.env) {
			case 'prod':
			case 'stg':
			case 'dev':
			case 'qa':
				systemType = (environment.systemType != null) ? environment.systemType : getTenantServer();
				serverConfig = `setups/${environment.env}-${systemType}`;
				break;
			case 'deployed':
			case 'local':
				serverConfig = `${environment.env}`;
				break;
			default:
				throw new Error(`Unsupported environemnt: ${environment.env}`);
		}

		appConfigService.load(`app-configs/${serverConfig}.json`)
			.then(() => resolve());
	});
}

function getTenantServer(): string {
	const currentURL = window.location.href;
	const regex = /-ts([0-9]+)./;

	const match = currentURL.match(regex);

	return (match !== null) ? `ts${currentURL.match(regex)[1]}` : 'central';
}

function loadDefaultLocale(appLangService: AppLangService) {
	import(`@angular/common/locales/en-GB.js`)
		.then(importedModule => {
			registerLocaleData(importedModule.default, 'en-GB');
			appLangService.selectedLocaleID = 'en-GB';
		});

	import(`@angular/common/locales/fr.js`)
		.then(importedModule => {
			registerLocaleData(importedModule.default, 'fr-FR');
		});
}

function getLang() {
	if (navigator.languages !== undefined) {
		return navigator.languages[0];
	}
	return navigator.language;
}
