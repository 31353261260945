/**
 * Class is a repository for the 'controller' part of the URLs to be generated when making HTTP requests
 */
export class ServiceController {
	public static GAME_CONTROLLER = 'api/game/';
	public static LOGIN_CONTROLLER = 'token';
	public static LOGGING_CONTROLLER = 'api/log/';
	public static USERS_CONTROLLER = 'api/Users/';
	public static GROUPS_CONTROLLER = 'api/UserGroups/';
	public static TENANTS_CONTROLLER = 'api/Tenants/';
	public static NOMENCLATURES_CENTRAL_CONTROLLER = 'api/CentralNomenclatures/';
	public static NOMENCLATURES_TENANT_CONTROLLER = 'api/TenantNomenclatures/';
	public static MULTILINGUAL_CONTROLLER = 'api/MultilingualReferences/';
	public static PLAYER_CONTROLLER = 'api/Player/';
	public static KYC_CONTROLLER = 'api/KYC/';
	public static PAYMENTMANEGEMENT_CONTROLLER = 'api/PaymentPlanManagement/';
	public static WINNING_PARTICIPATIONS = 'api/Winnings/';
	public static REPORTING_CONTROLLER = 'api/reporting/';
	public static AUDIT_CONTROLLER = 'api/Audit/';
	public static GAMEADMINISTRATION_CONTROLLER = 'api/GameAdministration/';
	public static FINANCIAL_CONTROLLER = 'api/financial/';
	public static PAGE_PERMISSIONS_CONTROLLER = 'api/pagepermissions/';
	public static ACTION_PERMISSIONS_CONTROLLER = 'api/actionpermissions/';
	public static BANK_ACCOUNT_CONTROLLER = 'api/BankAccounts/';
	public static BANK_CONTROLLER = 'api/Banks/';
	public static BANK_STATEMENT_CONTROLLER = 'api/BankStatements/';
	public static BANK_STATEMENT_TRANSACTIONS_CONTROLLER = 'api/BankStatementTransactions/';
	public static BANK_NOMINAL_MAPPING_CONTROLLER = 'api/NominalMappings/';
	public static WINNINGS_PARTICIPATIONS_CONTROLLER = 'api/Winnings/';
	public static POWER_BI = 'api/PowerBI/';
	public static COMPANY_CONTROLLER = 'api/Companies/';
	public static DRAW_CONTROLLER = 'api/Draw/';
	public static TICKET_BATCH_CONTROLLER = 'api/TicketBatch/';
	public static SUBSCRIPTIONS_CONTROLLER = 'api/Subscriptions/';
	public static SUBSCRIPTION_PLANS_CONTROLLER = 'api/SubscriptionPlans/';
	public static RETAIL_AGENT_CONTROLLER = 'api/retailagents/';
}
