import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppLayoutService } from '../layout/secure/app-layout.service';
import { LoginService, UsersService } from '../helio-core-services';
import { User } from '../shared/models';
import { Observable, Subscription } from 'rxjs';
import { runInThisContext } from 'vm';

@Component({
	selector: 'he-topbar',
	templateUrl: './app-topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
	mobileMenuActive = false;
	loggedInUser: User;
	isUserLoggedIn: boolean;
	userObservable: Subscription;

	constructor(
		private router: Router,
		private loginService: LoginService,
		private usersService: UsersService,
		private appLayoutService: AppLayoutService
	) { }

	ngOnInit() {
		this.appLayoutService.menuActiveSubject.subscribe(res => {
			this.mobileMenuActive = res;
		});

		this.isUserLoggedIn = localStorage.getItem('log_status') === 'true' ? true : false;

		if(this.isUserLoggedIn) {
			let userId = parseInt(localStorage.getItem('logged_in_userID'));
			this.userObservable = this.usersService.getUser(userId).subscribe(user => {
				this.loggedInUser = user;
			})
		}
	}

	ngOnDestroy() {
		this.userObservable.unsubscribe();
	}

	onMenuButtonClick(event: Event) {
		this.appLayoutService.menuButtonClick = true;

		if (this.appLayoutService.isMobile()) {
			this.mobileMenuActive = !this.mobileMenuActive;
			this.appLayoutService.menuActiveSubject.next(this.mobileMenuActive);
		}

		event.preventDefault();
	}

	goToProfile() {
		this.router.navigate(['my-profile']);
	}

	logOut() {
		// Clean data stored locally
		this.loginService.logOut();
		// Navigate to the login page
		this.router.navigate(['login']);
	}
}
