/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-access-rights.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/button/button";
import * as i3 from "../shared/components/header/header.component.ngfactory";
import * as i4 from "../shared/components/header/header.component";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./no-access-rights.component";
import * as i8 from "../helio-core-services/services/login.service";
var styles_NoAccessRightsComponent = [i0.styles];
var RenderType_NoAccessRightsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoAccessRightsComponent, data: {} });
export { RenderType_NoAccessRightsComponent as RenderType_NoAccessRightsComponent };
function View_NoAccessRightsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "flat"], ["icon", "ui-icon-exit-to-app"], ["label", "Logout"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "Logout"; var currVal_1 = "ui-icon-exit-to-app"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_NoAccessRightsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-header", [["headerTitle", "No Access Rights"]], null, null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.HeaderComponent, [], { headerTitle: [0, "headerTitle"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\tYou do not have rights to access this page. Please contact your administrator.\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Go to Home Page"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoAccessRightsComponent_1)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "No Access Rights"; _ck(_v, 1, 0, currVal_0); var currVal_3 = "/home"; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.isLoggedIn; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).target; var currVal_2 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_NoAccessRightsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-no-access-rights", [], null, null, null, View_NoAccessRightsComponent_0, RenderType_NoAccessRightsComponent)), i1.ɵdid(1, 114688, null, 0, i7.NoAccessRightsComponent, [i5.Router, i8.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoAccessRightsComponentNgFactory = i1.ɵccf("he-no-access-rights", i7.NoAccessRightsComponent, View_NoAccessRightsComponent_Host_0, {}, {}, []);
export { NoAccessRightsComponentNgFactory as NoAccessRightsComponentNgFactory };
