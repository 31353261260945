import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { BreadcrumbService } from '../helio-core-services';

@Component({
	selector: 'he-my-profile',
	styleUrls: [
		'./my-profile.component.scss'
	],
	templateUrl: './my-profile.component.html'
})

export class MyProfileComponent implements OnInit {
    showChangePasswordModal = false;

	constructor(
        private breadcumbService: BreadcrumbService,
    ) { }

	ngOnInit() {
		this.breadcumbService.setItems([{ label: 'My Profile' }]);
	}

    closePasswordModal() {
        this.showChangePasswordModal = false;   
    }

    initChangePassword() {
        this.showChangePasswordModal = true;   
    }
}
