/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./public-content.component";
var styles_PublicContentComponent = [i0.styles];
var RenderType_PublicContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicContentComponent, data: {} });
export { RenderType_PublicContentComponent as RenderType_PublicContentComponent };
export function View_PublicContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_PublicContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-public-content", [], null, null, null, View_PublicContentComponent_0, RenderType_PublicContentComponent)), i1.ɵdid(1, 49152, null, 0, i3.PublicContentComponent, [], null, null)], null, null); }
var PublicContentComponentNgFactory = i1.ɵccf("he-public-content", i3.PublicContentComponent, View_PublicContentComponent_Host_0, {}, {}, []);
export { PublicContentComponentNgFactory as PublicContentComponentNgFactory };
