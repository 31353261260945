import * as tslib_1 from "tslib";
// "import setValue from 'set-value'"; resulted in an error, so require is being used
// Most probably this is related to how the module is exported (https://stackoverflow.com/a/39415662/2312637)
var setValue = require('set-value');
/**
 * Utility Class to access object properties using dot notation in a string.
 * Class contains a get value and set value.
 * Set value function is dependant on a third party module 'set-value'
 */
var ObjPropsStringDotNotationUtility = /** @class */ (function () {
    function ObjPropsStringDotNotationUtility() {
    }
    /**
     * Function to get value of a property
     * @param data Object to get value from
     * @param objProperty string representing the property to access
     *
     * @example
     * let person = {
     * 	name: 'Peter',
     * 	surname: 'Pan',
     * 	contact: {
     * 	 address: '1, Neverland Street, Neverland',
     * 	 phone: 99999999
     * 	}
     * };
     *
     * // returns phone number
     * getObjValue(person, 'contact.phone');
     */
    ObjPropsStringDotNotationUtility.getObjValue = function (data, objProperty) {
        var e_1, _a;
        var properties = objProperty.split('.');
        var tempData = data;
        try {
            for (var properties_1 = tslib_1.__values(properties), properties_1_1 = properties_1.next(); !properties_1_1.done; properties_1_1 = properties_1.next()) {
                var tempProperty = properties_1_1.value;
                var tempValue = tempData[tempProperty];
                if (tempValue !== undefined && tempValue !== null) {
                    tempData = tempValue;
                }
                else {
                    tempData = undefined;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (properties_1_1 && !properties_1_1.done && (_a = properties_1.return)) _a.call(properties_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return tempData;
    };
    /**
     * Function to set value of a property
     * @param data Object to set value in
     * @param objProperty string representing the property to update
     * @param newValue Updated value
     *
     * @example
     * let person = {
     * 	name: 'Peter',
     * 	surname: 'Pan',
     * 	contact: {
     * 	 address: '1, Neverland Street, Neverland',
     * 	 phone: 99999999
     * 	}
     * };
     *
     * // updates phone number
     * setObjValue(person, 'contact.phone', 123456798);
     */
    ObjPropsStringDotNotationUtility.setObjValue = function (data, objProperty, newValue) {
        setValue(data, objProperty, newValue);
    };
    return ObjPropsStringDotNotationUtility;
}());
export { ObjPropsStringDotNotationUtility };
