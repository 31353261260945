import { Component, Input, OnInit } from '@angular/core';
import { ColumnType, PrimeNGColumnExtend } from '../shared';
import { AppConfigService } from '../../../../helio-core-services/services/app-config.service';
import { ObjPropsStringDotNotationUtility } from '../../../../shared/utilities';

@Component({
	selector: 'he-column-data',
	styleUrls: [
		'./column-data.component.scss'
	],
	templateUrl: './column-data.component.html'
})

export class ColumnDataComponent implements OnInit {
	@Input() column: PrimeNGColumnExtend;
	@Input() data: any;
	columnType: typeof ColumnType = ColumnType;

	dateFormat: string;
	timezoneOffset: string;
	numberFormat: string;
	moneyFormat: string;

	constructor(private appConfigService: AppConfigService) { }

	ngOnInit() {
		this.dateFormat = this.appConfigService.defaultDateFormat;
		this.timezoneOffset = this.appConfigService.defaultTimezoneOffset;
		this.numberFormat = this.appConfigService.defaultNumberFormat;
		this.moneyFormat = this.appConfigService.defaultMoneyFormat;
	}

	getTooltipString(data: any[]): string {
		return data.join(', ');
	}
}
