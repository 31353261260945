import * as tslib_1 from "tslib";
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
export function appInitLoader(appLangService, appConfigService) {
    var _this = this;
    return function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, appConfigLoader(appConfigService)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, loadDefaultLocale(appLangService)];
                case 2:
                    _a.sent();
                    return [2 /*return*/, Promise.resolve()];
            }
        });
    }); };
}
function appConfigLoader(appConfigService) {
    return new Promise(function (resolve) {
        var serverConfig;
        var systemType;
        switch (environment.env) {
            case 'prod':
            case 'stg':
            case 'dev':
            case 'qa':
                systemType = (environment.systemType != null) ? environment.systemType : getTenantServer();
                serverConfig = "setups/" + environment.env + "-" + systemType;
                break;
            case 'deployed':
            case 'local':
                serverConfig = "" + environment.env;
                break;
            default:
                throw new Error("Unsupported environemnt: " + environment.env);
        }
        appConfigService.load("app-configs/" + serverConfig + ".json")
            .then(function () { return resolve(); });
    });
}
function getTenantServer() {
    var currentURL = window.location.href;
    var regex = /-ts([0-9]+)./;
    var match = currentURL.match(regex);
    return (match !== null) ? "ts" + currentURL.match(regex)[1] : 'central';
}
function loadDefaultLocale(appLangService) {
    import("@angular/common/locales/en-GB.js")
        .then(function (importedModule) {
        registerLocaleData(importedModule.default, 'en-GB');
        appLangService.selectedLocaleID = 'en-GB';
    });
    import("@angular/common/locales/fr.js")
        .then(function (importedModule) {
        registerLocaleData(importedModule.default, 'fr-FR');
    });
}
function getLang() {
    if (navigator.languages !== undefined) {
        return navigator.languages[0];
    }
    return navigator.language;
}
