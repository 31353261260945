import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[heShowNavItem]'
})

export class ShowNavItemDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) { }

	@Input() set heShowNavItem(url: string) {
		if (this.checkPagePermissions(url)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	private checkPagePermissions(url: string): boolean {
		const urlList = url.split('/');
		const localStorageList = JSON.parse(JSON.parse(localStorage.getItem('access_rights')).Pages);
		let hasRights = true;

		let route;
		for (let i = 0; i < urlList.length; i++) {
			if (urlList[i] !== '') {
				const tempURL = urlList[i];
				const pagePermissions: any[] = (i === 0) ? localStorageList : route.ChildPages;

				route = pagePermissions.find(tempPageObj => {
					return tempPageObj.Name === tempURL;
				});

				if (route === undefined) {
					hasRights = false;
					break;
				}
			}
		}

		return hasRights;
	}
}
