/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kitchen-sink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./kitchen-sink.component";
var styles_KitchenSinkComponent = [i0.styles];
var RenderType_KitchenSinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KitchenSinkComponent, data: {} });
export { RenderType_KitchenSinkComponent as RenderType_KitchenSinkComponent };
export function View_KitchenSinkComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_KitchenSinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "he-kitchen-sink", [], null, null, null, View_KitchenSinkComponent_0, RenderType_KitchenSinkComponent)), i1.ɵdid(1, 114688, null, 0, i2.KitchenSinkComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KitchenSinkComponentNgFactory = i1.ɵccf("he-kitchen-sink", i2.KitchenSinkComponent, View_KitchenSinkComponent_Host_0, {}, {}, []);
export { KitchenSinkComponentNgFactory as KitchenSinkComponentNgFactory };
