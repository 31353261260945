import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';

import { TenantType, Tenant } from '../../tenant-management/shared';
import { DataTableServerResponse } from '../../shared/components/data-table-v3';
import { ServiceController, ServiceAction } from '../../shared/utilities/service-utilities';
import { ResponseBodyType } from '../../shared/enums';

/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class TenantService extends BaseService {
	public tenants: Tenant[] = [];
	public retailTenants: Tenant[] = [];
	public tenantTypes: TenantType[] = [];
	private loggedOperatorIDs: string = JSON.parse(localStorage.getItem('access_rights')).TenantIDs;

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.TENANTS_CONTROLLER, appConfigService.serviceBaseURL);
	}

	public getTenants(): Observable<Tenant[]> {
		if (this.tenants.length === 0) {
			const tenantParams = new HttpParams()
					.set('$take', '1000')
				.set('$offset', '0')
				.set('$filter', 'isActive eq true');
			// commented as filtering should be done server side
			// if (this.loggedOperatorIDs.length > 0) {
			// 	let operatorIDs: string[];
			// 	operatorIDs = this.loggedOperatorIDs.split(',');

			// 	let filter = '';
			// 	operatorIDs.forEach(element => {
			// 		filter += `TenantID eq ${element} OR `;
			// 	});

			// 	const pos = filter.lastIndexOf(' OR ');
			// 	filter = filter.substring(0, pos);

			// 	tenantParams = new HttpParams()
			// 		.set('$take', operatorIDs.length.toString())
			// 		.set('$filter', filter);
			// }

			return this.getTenantsFromServer(tenantParams).pipe(
				map(res => { this.tenants = res.resultSet; return this.tenants; }));
		} else {
			return of(this.tenants);
		}
	}

	public getRetailTenants(): Observable<Tenant[]> {
		if (this.retailTenants.length === 0) {
			return this.getRetailTenantsFromServer().pipe(
				map(res => { this.retailTenants = res.resultSet; return this.retailTenants; }));
		} else {
			return of(this.retailTenants);
		}
	}

	public getTenantTypes(): Observable<TenantType[]> {
		if (this.tenantTypes.length === 0) {
			return this.getTenantTypesFromServer().pipe(
				map(res => { this.tenantTypes = res; return this.tenantTypes; }));
		} else {
			return of(this.tenantTypes);
		}
	}

	public getTenantsFromServer(searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.get<DataTableServerResponse>(ServiceAction.GENERIC_GET, undefined, searchParams);
	}

	public getTenantsFromServerCsv(searchParams?: HttpParams) {
		return this.get(ServiceAction.GENERIC_GET, undefined, searchParams, ResponseBodyType.Text);
	}

	public getRetailTenantsFromServer(): Observable<DataTableServerResponse> {
		const searchParams = new HttpParams().set('$take', '10').set('$offset', '0').set('$filter', 'IntegrationTypeID eq 0');
		return this.get<DataTableServerResponse>(ServiceAction.TENANTS_GETRETAIL, undefined, searchParams);
	}

	public getTenantTypesFromServer(): Observable<TenantType[]> {
		return this.get(ServiceAction.TENANTMANAGEMENT_TYPES);
	}

	public createOperatorOrBroker(isBroker = false, postData: Tenant) {
		const action = !isBroker ? ServiceAction.TENANTMANAGEMENT_CREATEOPERATOR : ServiceAction.TENANTMANAGEMENT_CREATEBROKER;
		return this.post(action, postData);
	}

	public updateOperatorOrBroker(isBroker = false, editData: Tenant) {
		const action = !isBroker ? ServiceAction.TENANTMANAGEMENT_UPDATEOPERATOR : ServiceAction.TENANTMANAGEMENT_UPDATEBROKER;
		return this.edit(action, editData);
	}

	public getContactTypes() {
		return this.get(ServiceAction.TENANTMANAGEMENT_CONTACTTYPES);
	}

	public getIntegrationTypes() {
		return this.get(ServiceAction.TENANTMANAGEMENT_INTEGRATIONTYPES);
	}
}
