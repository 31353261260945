import { HttpHeaders } from '@angular/common/http';

import { ServiceHeadersConfig } from './service-headers.config';

/**
 * Utility class to set the request headers
 */
export class ServiceHeadersUtility {
	/**
	 * Function to set the request headers
	 * @param headersConfig Object to set whether the request headers should include the Authorization header
	 * as well as set the Content-Type header
	 * @return The request headers to be used for the HTTP request
	 */
	public static httpHeaders(headersConfig: ServiceHeadersConfig): HttpHeaders {
		let headers: HttpHeaders = new HttpHeaders();

		if (!headersConfig.isAnonymous) {
			headers = headers.set('Authorization', localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token'));
		}

		if (headersConfig.contentType !== '') {
			headers = headers.set('Content-Type', headersConfig.contentType);
		}

		return headers;
	}
}
