import { Directive,  OnInit, ElementRef } from '@angular/core';


@Directive({ 
    selector: '[heTogglePasswordVisibility]' 
})
export class TogglePasswordVisibilityDirective implements OnInit {
    private isHidden = true;

    private hiddenTemplate = `visibility`;
    private notHiddenTemplate = `visibility_off`;
    constructor(private el: ElementRef) { }

    ngOnInit() {
      this.init();
    }

    toggle(span: HTMLElement) {
        this.isHidden = !this.isHidden;
        if (this.isHidden) {
          this.el.nativeElement.setAttribute('type', 'password');
          span.innerHTML = this.hiddenTemplate;
        } else {
          this.el.nativeElement.setAttribute('type', 'text');
          span.innerHTML = this.notHiddenTemplate;
        }
      }

    init() {
        const parent = this.el.nativeElement.parentNode;
        const icon = document.createElement('i');
        icon.classList.add('material-icons');
        icon.style.cssText = `cursor: pointer;
        user-select: none;
        position: absolute;
        top: 0;
        right: 6px;
        color: #7d7d7d;`;

        icon.innerHTML = this.hiddenTemplate;
        icon.addEventListener('click', (event) => {
            this.toggle(icon);
        });
        parent.appendChild(icon);
    }


}