import { Injectable } from '@angular/core';

import { Observable, fromEvent } from 'rxjs';

@Injectable()
export class WindowEventsService {
	onBlur: Observable<Event>;
	onFocus: Observable<Event>;

	constructor() {
		this.onBlurHandler();
		this.onFocusHandler();
	}

	private onBlurHandler() {
		this.onBlur = fromEvent(window, 'blur');
	}

	private onFocusHandler() {
		this.onFocus = fromEvent(window, 'focus');
	}
}
