import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { ChangePasswordComponent } from './change-password.component';
import { ChangePasswordService } from './change-password.service';
import { SharedModule } from '../shared';

@NgModule({
	declarations: [
		ChangePasswordComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DialogModule,
		ButtonModule,
		InputTextModule,
		SharedModule
	],
	providers: [
		ChangePasswordService
	],
	exports: [
		ChangePasswordComponent
	],
	entryComponents: [
		ChangePasswordComponent
	]
})
export class ChangePasswordModule { }
