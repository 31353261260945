import { Component } from '@angular/core';

@Component({
	selector: 'he-app-title',
	styleUrls: [
		'./app-title.component.scss'
	],
	templateUrl: './app-title.component.html'
})

export class AppTitleComponent { }
