<ng-container *ngIf="isModal; else notModalTemplate">
	<p-dialog header="{{headerMessage}}" [visible]="true" modal="modal" [style]="{ width: '400px' }"
		[responsive]="true" [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false">
		<ng-container *ngTemplateOutlet="formContent"></ng-container>
		<p-footer>
			<ng-container *ngTemplateOutlet="formButtonsContent"></ng-container>
		</p-footer>
	</p-dialog>
	<!-- Reset Password INFO dialog -->
	<p-dialog header="Alert" [(visible)]="displayInfo" showEffect="fade" [modal]="true" [style]="{ width: '500px' }">
		{{ information }}
		<p-footer>
			<div>
				<button type="button" pButton icon="ui-icon-check" (click)="closeInfoAlert()" label="Okay"></button>
			</div>
		</p-footer>
	</p-dialog>
</ng-container>

<ng-template #notModalTemplate>
	<div class="form-wrapper">
		<p>Change password:</p>
		<ng-container *ngTemplateOutlet="formContent"></ng-container>
		<ng-container *ngTemplateOutlet="formButtonsContent"></ng-container>
	</div>
</ng-template>

<ng-template #formContent>
	<form [formGroup]="changePasswordForm">
		<div class="ui-g">
			<div class="ui-g-12 ui-md-12">
				<span class="ui-float-label ui-g-12 ui-md-12">
					<input heTogglePasswordVisibility pInputText class="float-input" type="password" formControlName="oldPassword"  />
					<label class="float-input">Old Password</label>
				</span>
			</div>
			<div class="ui-g-12 ui-md-12">
				<span class="ui-float-label ui-g-12 ui-md-12" >
					<input heTogglePasswordVisibility pInputText class="float-input" type="password" formControlName="newPassword" />
					<label class="float-input">New Password</label>
				</span>
				<div class="ui-g-12 alert" *ngIf="!changePasswordForm.valid && !changePasswordForm.pristine">
					{{ errors }}
				</div>
			</div>
			<div class="ui-g-12 ui-md-12">
				<span class="ui-float-label ui-g-12 ui-md-12">
					<input heTogglePasswordVisibility pInputText class="float-input" type="password" formControlName="confirmNewPassword" />
					<label class="float-input">Confirm New Password</label>
				</span>
				<div class="ui-g-12 alert" *ngIf="!changePasswordForm.valid && !changePasswordForm.pristine">
					{{ confirmPasswordError }}
				</div>
			</div>
		</div>
	</form>
</ng-template>

<ng-template #formButtonsContent>
	<button type="button" pButton icon="ui-icon-check" [disabled]="!changePasswordForm.valid" label="Save" name="submitButton" title="Save Password" (click)="saveNewPassword()"></button>
</ng-template>