import { OnInit } from '@angular/core';
import { Login } from '../shared';
import { FormControl, Validators } from '@angular/forms';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(router, loginService, route, formBuilder) {
        this.router = router;
        this.loginService = loginService;
        this.route = route;
        this.formBuilder = formBuilder;
        this.loginModel = new Login();
        this.isLoginFormLoading = false;
        this.loginFailed = false;
        this.loginDetailsInvalid = false;
        this.checksum = '-1';
        this.isSessionExpired = false;
        this.progressSpinnerStyle = {
            width: '28px',
            height: '28px',
            position: 'relative',
            top: '10px',
            right: '10px'
        };
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formInit();
        this.route.queryParamMap.subscribe(function (params) {
            if (params.has('sessionExpired')) {
                _this.isSessionExpired = (params.get('sessionExpired') === 'true');
            }
            if (params.has('redirectURL')) {
                _this.redirectURL = params.get('redirectURL');
            }
        });
    };
    LoginComponent.prototype.submitLogin = function () {
        var _this = this;
        if (!this.isLoginFormLoading) {
            this.isLoginFormLoading = true;
            this.loginFailed = false;
            this.loginModel.username = this.loginForm.get('username').value;
            this.loginModel.password = this.loginForm.get('password').value;
            this.loginService.loginUser(this.loginModel)
                .subscribe(function (loginResponse) {
                if (_this.loginService.isLoggedIn) {
                    _this.isLoginFormLoading = false;
                    localStorage.setItem('user_name', _this.loginModel.username);
                    if (_this.redirectURL !== undefined) {
                        _this.router.navigateByUrl(_this.redirectURL);
                    }
                    else {
                        _this.router.navigateByUrl('/home');
                    }
                }
            }, function (error) {
                _this.isLoginFormLoading = false;
                _this.loginFailed = true;
                _this.loginModel.password = '';
            });
        }
    };
    Object.defineProperty(LoginComponent.prototype, "isLoginValid", {
        get: function () {
            var username = this.loginForm.get('username');
            var password = this.loginForm.get('password');
            return (username.valid || username.pristine) && (password.valid || password.pristine);
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.formInit = function () {
        this.loginForm = this.formBuilder.group({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
