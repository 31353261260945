import {
	AfterViewInit, Component, ComponentFactoryResolver, ComponentFactory,
	EventEmitter, Input, Output, ViewChild, ViewContainerRef
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { SubmitSearchEvent } from './submit-search-event.model';

import { SearchField } from './search-field.model';
import { SearchFieldType } from './search-field-type.enum';
import { AdvancedSearchFieldHostDirective } from './advanced-search-field-host.directive';
import { AdvancedSearchFieldComponent } from './advanced-search-field';
import { AdvancedSearchService, ToastDisplayService } from '../../../../helio-core-services';
import { ToastMessageType } from '../../../models/general';

@Component({
	selector: 'he-advanced-search',
	styleUrls: [
		'./advanced-search.component.scss'
	],
	templateUrl: './advanced-search.component.html'
})

export class AdvancedSearchComponent implements AfterViewInit {
	@Input() searchFields: SearchField[];
	@Output() submitSearch: EventEmitter<SubmitSearchEvent> = new EventEmitter<SubmitSearchEvent>();
	@Output() clearSearch: EventEmitter<void> = new EventEmitter<void>();
	@Output() closeSearch: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('searchFieldHost', { read: AdvancedSearchFieldHostDirective, static: false }) searchFieldHost: AdvancedSearchFieldHostDirective;

	searchFieldToAdd: SearchField;
	searchFieldComponents: AdvancedSearchFieldComponent[] = [];

	searchFieldComponentFactory: ComponentFactory<AdvancedSearchFieldComponent>;
	
	private urlPath = window.location.hash;

	// used to generate a unique ID for each new search field component,
	// which is then used to identify the component reference on delete
	searchFieldCount = 0;
	hasEmptySearchValues = false;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private toastDisplayService: ToastDisplayService,
		private advancedSearchService: AdvancedSearchService
	) { }

	ngAfterViewInit() {
		this.searchFieldComponentFactory = this.componentFactoryResolver.resolveComponentFactory(AdvancedSearchFieldComponent);
		setTimeout(() => {
			this.readdSearchFields();
		}, 0);
	}

	readdSearchFields() {
		if (!this.advancedSearchService.fields.get(this.urlPath)) return;

		const viewContainerRef: ViewContainerRef = this.searchFieldHost.viewContainerRef;
		
		let length = this.advancedSearchService.fields.get(this.urlPath).length;
		this.advancedSearchService.fields.get(this.urlPath).forEach((f, i) => {
			const componentRef = viewContainerRef.createComponent(this.searchFieldComponentFactory);
			const componentInstance = componentRef.instance;
			componentInstance.searchField = Object.assign({}, f);
			componentInstance.searchFieldComponentRef = componentRef;
			componentInstance.searchFieldID = ++this.searchFieldCount;
			componentInstance.isFirstSearchField = (this.searchFieldComponents.length === 0);
			componentInstance.selectedSearchAndOr = f.selectedSearchAndOr;
			componentInstance.destroyedComponent.subscribe(id => this.updateSearchFieldComponentsList(id));

			this.searchFieldComponents.push(componentInstance);
		});
	
		setTimeout(() => {
			this.submitSearchClick();
		}, 0);
	}

	submitSearchClick() {
		const tempFilterString = this.getFilterString();

		if (tempFilterString === undefined) {
			this.submitSearch.emit();
		} else {
			this.submitSearch.emit({
				searchFields: this.searchFields,
				filterString: tempFilterString,
				urlParams: new HttpParams().set('$filter', tempFilterString)
			});
		}

		this.advancedSearchService.setShowAdvancedSearch(this.urlPath, true);
		this.advancedSearchService.fields.set(this.urlPath, this.searchFieldComponents.map(fc => {
			fc.searchField.selectedSearchAndOr = fc.selectedSearchAndOr;
			return fc.searchField;
		}));

		if (this.hasEmptySearchValues) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.info,
				title: 'Info Message',
				description: 'Your search query was submitted, but note that empty search values are ignored. Are you sure this was not a mistake?'
			});
		}
	}

	clearSearchClick() {
		// clear data in searchFields, emit undefined
		this.clearSearch.emit();
		this.resetSearchFields();
		this.advancedSearchService.fields.delete(this.urlPath);
	}

	addSearchField() {
		if (this.searchFieldToAdd === undefined) {
			this.toastDisplayService.addMessage({
				type: ToastMessageType.error,
				title: 'Error Message',
				description: 'Please select a Search Field from the dropdown.'
			});
		} else {
			const viewContainerRef: ViewContainerRef = this.searchFieldHost.viewContainerRef;
			const componentRef = viewContainerRef.createComponent(this.searchFieldComponentFactory);

			const componentInstance = componentRef.instance;
			componentInstance.searchField = Object.assign({}, this.searchFieldToAdd);
			componentInstance.searchFieldComponentRef = componentRef;
			componentInstance.searchFieldID = ++this.searchFieldCount;
			componentInstance.isFirstSearchField = (this.searchFieldComponents.length === 0);

			componentInstance.destroyedComponent.subscribe(id => this.updateSearchFieldComponentsList(id));

			this.searchFieldComponents.push(componentInstance);
			this.searchFieldToAdd = undefined;
		}
	}

	closeSearchClick() {
		this.clearSearchClick();
		this.closeSearch.emit();
		// this.advancedSearchService.setShowAdvancedSearch(this.urlPath, false);
	}

	private resetSearchFields(): void {
		this.searchFieldComponents.forEach(temp => {
			const searchField = temp.searchField;
			if (searchField.type === SearchFieldType.Number) {
				if (searchField.hasRange && typeof searchField.searchValue !== 'string') {
					const resetRange = [
						searchField.minNumberRange,
						searchField.maxNumberRange
					];

					searchField.searchValue = resetRange.slice();
					temp.numberRangeMinValue = searchField.minNumberRange;
					temp.numberRangeMaxValue = searchField.maxNumberRange;
				}
			} else {
				searchField.searchValue = undefined;
			}
			searchField.filterString = undefined;
		});
	}

	private getFilterString(): string {
		this.hasEmptySearchValues = false;
		let filterString;

		this.searchFieldComponents.forEach((temp, i) => {
			const searchFilterString = temp.searchField.filterString;
			if (searchFilterString !== undefined) {
				if (i === 0) {
					filterString = searchFilterString;
				} else {
					filterString += ` ${temp.selectedSearchAndOr} ${searchFilterString}`;
				}
			} else {
				this.hasEmptySearchValues = true;
			}
		});

		return filterString;
	}

	private updateSearchFieldComponentsList(id: number) {
		const index = this.searchFieldComponents.findIndex(temp => temp.searchFieldID === id);
		this.searchFieldComponents.splice(index, 1);

		if (index === 0 && this.searchFieldComponents.length > 0) {
			this.searchFieldComponents[0].isFirstSearchField = true;
		}
	}
}
