export class ValidationService {
	rangeNumbers: any = {};

	static reset(form: any, setEmptyValue: boolean = true) {
		const controls = form.controls;
		for (const key in controls) {
			if (form.controls.hasOwnProperty(key)) {
				controls[key].markAsPristine(false);
				controls[key].markAsUntouched(true);
				if (setEmptyValue) {
					controls[key].setValue('');
				}
			}
		}
	}

	static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
		const config = {
			'required': 'Required',
			'minlength': `Minimum length: ${validatorValue.requiredLength}`,
			'maxlength': `Maximum length: ${validatorValue.requiredLength}`,
			'numeric': `Ivalid Value`,
			'maxValue': `Maximum value`,
			'minValue': `Minimum value`,
			'rangeValue': `Value Range : ${validatorValue.min} - ${validatorValue.max}`,
			'email': 'Please enter correct email',
			'invalidEmailAddress': 'Please enter correct email',
			'invalidDomainAddress': 'Please enter valid domain',
			'invalidUrl': 'Please enter valid URL',
			'invalidPassword': 'Invalid password. Must contain at least one uppercase, one lowercase and numbers',
			'invalidNumber': 'Only numbers are allowed',
			'hasNumber': 'At least one number',
			'hasLowerCase': 'At least one lower case letter',
			'hasUpperCase': 'At least one upper case letter',
			'hasSymbol': 'At least one symbol',
			'containsUsername': 'Password cannot contain the username',
			'isSameString' : 'New password cannot be the same as your old one',
			'isNotSameString' : 'New password and confirm passwords must match'
		};

		return config[validatorName];
	}

	static rangeLength(param) {
		return (control) => {
			const val: any = control.value;
			if (!isNaN(param.min) && !isNaN(param.max)) {
				return val < param.min || val > param.max ? { rangeValue: param } : null;
			} else {
				return null;
			}
		};
	}

	static numeric(control) {
		const val: any = control.value;
		if (isNaN(val) || /\D/.test(val.toString())) {
			return { 'numeric': true };
		} else {
			return null;
		}
	}

	static emailValidator(control) {
		// tslint:disable-next-line:max-line-length
		if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
			return null;
		} else {
			return { 'invalidEmailAddress': true };
		}
	}

	static domainValidator(control) {
		// tslint:disable-next-line:max-line-length
		if (control.value.match(/^[a-z0-9][a-z0-9\-]*[a-z0-9]$/)) {
			return null;
		} else {
			return { 'invalidDomainAddress': true };
		}
	}

	static urlValidator(control) {
		if (control.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)) {
			return null;
		} else {
			return { 'invalidUrl': true };
		}
	}

	static passwordValidation(control) {
		if (control.value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) {
			return null;
		} else {
			return { 'invalidPassword': true };
		} // at least one lower case, one Uppercase and one number validation
	}

	static numberValidation(control) {
		console.log(control.value);
		if (control.value.match(/^\d+$/)) {
			return null;
		} else {
			return { 'invalidNumber': true };
		}
	}
}
