import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities';
/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
var GroupsService = /** @class */ (function (_super) {
    tslib_1.__extends(GroupsService, _super);
    /**
     * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
     * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
     */
    function GroupsService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.GROUPS_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    GroupsService.prototype.createGroup = function (group) {
        return this.post(undefined, group);
    };
    GroupsService.prototype.editGroup = function (group) {
        return this.edit(undefined, group);
    };
    GroupsService.prototype.getGroups = function (searchParams) {
        return this.get(undefined, undefined, searchParams);
    };
    return GroupsService;
}(BaseService));
export { GroupsService };
