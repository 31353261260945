import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { atLeastOneLowerCaseLetter, atLeastOneUpperCaseLetter, atLeastOneNumber, atLeastOneSymbol, containsUsername, sameString, isNotSameString } from '../shared/validators/validators';
import { ValidationService } from '../shared/components/form-messages/validation.service';
import { LoginService } from '../helio-core-services';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(formBuilder, appLoaderService, userService, loginService, changePasswordService, boErrorHandlerService, router) {
        this.formBuilder = formBuilder;
        this.appLoaderService = appLoaderService;
        this.userService = userService;
        this.loginService = loginService;
        this.changePasswordService = changePasswordService;
        this.boErrorHandlerService = boErrorHandlerService;
        this.router = router;
        this.isModal = true;
        this.headerMessage = "Your Password must be changed";
        this.displayInfo = false;
        this.refreshTokenSuccessful = false;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.userID = +localStorage.getItem('logged_in_userID');
        this.initForm();
    };
    ChangePasswordComponent.prototype.closeInfoAlert = function () {
        this.displayInfo = false;
        if (this.refreshTokenSuccessful) {
            this.changePasswordService.destroyChangePasswordDialog();
        }
        else {
            this.loginService.logOut();
            this.router.navigate(['login']);
        }
    };
    ChangePasswordComponent.prototype.saveNewPassword = function () {
        var _this = this;
        this.appLoaderService.isLoading = true;
        var newPasswordData = {
            userID: this.userID,
            oldPassword: this.changePasswordForm.get('oldPassword').value,
            newPassword: this.changePasswordForm.get('newPassword').value
        };
        this.userService.changePassword(newPasswordData)
            .subscribe(function (res) {
            _this.refreshToken();
        }, function (error) {
            _this.appLoaderService.isLoading = false;
            _this.boErrorHandlerService.handleError(error, undefined, 'Changing Password');
        });
    };
    ChangePasswordComponent.prototype.refreshToken = function () {
        var _this = this;
        this.loginService.refreshUserToken(false).subscribe(function () {
            _this.appLoaderService.isLoading = false;
            _this.information = 'Your Password has been changed successfully!';
            _this.displayInfo = true;
            _this.refreshTokenSuccessful = true;
        }, function (error) {
            _this.appLoaderService.isLoading = false;
            _this.information =
                'Your Password has been changed successfully! You will now be logged out and you may login again with your new Password.';
            _this.displayInfo = true;
            _this.refreshTokenSuccessful = false;
        });
    };
    ChangePasswordComponent.prototype.initForm = function () {
        var _this = this;
        var currentUsername = localStorage.getItem('user_name');
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: [''],
            confirmNewPassword: ['']
        });
        // set new password validtors
        this.changePasswordForm.get('newPassword').setValidators([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(128),
            atLeastOneLowerCaseLetter(),
            atLeastOneUpperCaseLetter(),
            atLeastOneNumber(),
            atLeastOneSymbol(),
            function (control) { return containsUsername(currentUsername)(control); },
            function (control) { return sameString(_this.changePasswordForm.get('oldPassword').value)(control); }
        ]);
        console.log("THE CONTROL:", this.changePasswordForm.get('confirmNewPassword'));
        this.changePasswordForm.get('confirmNewPassword').setValidators([
            Validators.required,
            function (control) { return isNotSameString(_this.changePasswordForm.get('newPassword').value)(control); }
        ]);
        this.changePasswordForm.get('newPassword').valueChanges
            .subscribe(function () {
            var e_1, _a;
            var validatorErrors = _this.changePasswordForm.get('newPassword').errors;
            if (validatorErrors !== undefined && validatorErrors !== null) {
                var objectKeys = Object.keys(validatorErrors);
                try {
                    for (var objectKeys_1 = tslib_1.__values(objectKeys), objectKeys_1_1 = objectKeys_1.next(); !objectKeys_1_1.done; objectKeys_1_1 = objectKeys_1.next()) {
                        var tempValidator = objectKeys_1_1.value;
                        var error = ValidationService.getValidatorErrorMessage(tempValidator, validatorErrors[tempValidator]);
                        if (error !== undefined) {
                            _this.errors = error;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (objectKeys_1_1 && !objectKeys_1_1.done && (_a = objectKeys_1.return)) _a.call(objectKeys_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
        this.changePasswordForm.get('confirmNewPassword').valueChanges
            .subscribe(function () {
            var e_2, _a;
            var validatorErrors = _this.changePasswordForm.get('confirmNewPassword').errors;
            if (validatorErrors !== undefined && validatorErrors !== null) {
                var objectKeys = Object.keys(validatorErrors);
                try {
                    for (var objectKeys_2 = tslib_1.__values(objectKeys), objectKeys_2_1 = objectKeys_2.next(); !objectKeys_2_1.done; objectKeys_2_1 = objectKeys_2.next()) {
                        var tempValidator = objectKeys_2_1.value;
                        var error = ValidationService.getValidatorErrorMessage(tempValidator, validatorErrors[tempValidator]);
                        if (error !== undefined) {
                            _this.confirmPasswordError = error;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (objectKeys_2_1 && !objectKeys_2_1.done && (_a = objectKeys_2.return)) _a.call(objectKeys_2);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
