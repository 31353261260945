import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { ServiceController, ServiceAction } from '../../shared/utilities/service-utilities';
/**
 * Service class used to handle HTTP requests related to Login
 */
var UsersService = /** @class */ (function (_super) {
    tslib_1.__extends(UsersService, _super);
    // private accessToken = localStorage.getItem('access_token');
    /**
     * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
     * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
     */
    function UsersService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.USERS_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    UsersService.prototype.getUsers = function (searchParams) {
        return this.get(undefined, undefined, searchParams);
    };
    UsersService.prototype.getUser = function (userID) {
        return this.get(undefined, [userID]);
    };
    UsersService.prototype.createUser = function (user) {
        return this.post(undefined, user);
    };
    UsersService.prototype.editUser = function (user) {
        return this.edit(undefined, user);
    };
    UsersService.prototype.deleteUser = function (id) {
        return this.delete(ServiceAction.GENERIC_DELETE, [id]);
    };
    UsersService.prototype.changePassword = function (data) {
        return this.edit(ServiceAction.USER_CHANGE_PASSWORD, data);
    };
    UsersService.prototype.resetPassword = function (userID) {
        return this.edit(ServiceAction.USER_RESET_PASSWORD, userID);
    };
    return UsersService;
}(BaseService));
export { UsersService };
