import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
import { DataTableServerResponse } from '../../shared/components/data-table-v3/shared';


/**
 * Service class used to handle HTTP requests related to the Groups Service
 */
@Injectable()
export class AuditService extends BaseService {

	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.AUDIT_CONTROLLER, appConfigService.serviceBaseURL);
	}

	getUserLogs(searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.get<DataTableServerResponse>(ServiceAction.GENERIC_GET, undefined, searchParams);
	}
}
