/**
 * Class is a repository for the 'action' part of the URLs to be generated when making HTTP requests
 */
export class ServiceAction {
	// GENERIC ACTIONS
	public static GENERIC_ADD = 'add';
	public static GENERIC_CREATE = 'create';
	public static GENERIC_DELETE = 'delete';
	public static GENERIC_GET = 'get';
	public static GENERIC_UPDATE = 'Update';
	public static GENERIC_GAMES = 'Games';
	public static GENERIC_POST = 'post';
	public static GENERIC_PUT = 'put';

	// USER ACTIONS
	public static USER_CHANGE_PASSWORD = 'ChangePassword';
	public static USER_RESET_PASSWORD = 'OverridePassword';

	// GAME ACTIONS
	public static GAME_GET = 'gamebyid';
	public static GAME_GAMEBYID = 'GameByID';
	public static GAME_SECONDARYGAMES = 'SecondaryGames';
	public static GAME_DEFAULTGAMES = 'DefaultGames';
	public static GAMEGROUP_GAMEGROUPBYID = 'GameGroupByID';
	public static GAMEGROUP_CURRENT_GAMEGROUP_RULE_BOARDS = 'GetCurrentGameRuleBoardsByGroupID';

	// MULTILINGUAL ACTIONS
	public static MULTILINGUAL_GETBYLANGUAGE = 'GetAllByLanguageId';

	// PLAYER MANAGEMENT ACTIONS
	public static PLAYERMANAGEMENT_GETPLAYERS = 'GetPlayersPartialData';
	public static PLAYERMANAGEMENT_GETPLAYERTRANSACTION = 'GetPlayerTransaction';
	public static PLAYERMANAGEMENT_LIFETIMEDATA = 'GetPlayerLifeTimeData';
	public static PLAYERMANAGEMENT_PLAYERTRANSACTIONS = 'GetPlayerTransactions';
	public static PLAYERMANAGEMENT_COUPONTRANSACTIONS = 'GetCouponTransactions';
	public static PLAYERMANAGEMENT_PLAYERPARTICIPATIONS = 'GetPlayerParticipations';
	public static PLAYERMANAGEMENT_PLAYERKYC = 'GetPlayerKYCStatuses';
	public static PLAYERMANAGEMENT_PAYMENTPLANS = 'GetPlayerStaggeredPayments';

	// AWARD FREE TICKETS ACTIONS
	public static AWARDTICKETS_GETGAMESDATA = 'GetGamesPartialData';
	public static AWARDTICKETS_GETBATCHESDATA = 'GetFreeTicketBatchesPartialData';
	public static AWARDTICKETS_ASSIGNTICKETS = 'AssignFreeTicketsAward';

	// KYC ACTIONS
	public static KYC_KYCDOCTYPES = 'KYCDocumentTypes';
	public static KYC_KYCDOCUMENTS = 'KYCDocuments';
	public static KYC_DOCREFERENCES = 'KYCDocumentReferences';

	// PAYMENT ACTIONS
	public static PAYMENT_WINSTATUSES = 'GetPaymentPlanStatuses';
	public static PAYMENT_CHANGESTATUS = 'ChangePaymentPlanStatus';
	public static PAYMENT_CHANGEINST = 'ChangePaymentPlanInstalments';

	// WINNINGS
	public static WINNING_PARTICIPATIONSTATUSES = 'GetWinningParticipationAuthorisationStatuses';
	public static WINNING_CHANGEAUTHSTATUS = 'ChangeWinningParticipationAuthorisationStatus';

	// TENANT MANAGEMENT
	public static TENANTS_GET = 'Get';
	public static TENANTS_GETRETAIL = 'GetRetail';
	public static TENANTMANAGEMENT_TYPES = 'GetTenantTypes';
	public static TENANTMANAGEMENT_CONTACTTYPES = 'GetContactTypes';
	public static TENANTMANAGEMENT_INTEGRATIONTYPES = 'GetIntegrationTypes';
	public static TENANTMANAGEMENT_CREATECONTACT = 'CreateContact';
	public static TENANTMANAGEMENT_CREATEOPERATOR = 'CreateOperator';
	public static TENANTMANAGEMENT_CREATEBROKER = 'CreateTenantBroker';
	public static TENANTMANAGEMENT_UPDATEOPERATOR = 'UpdateOperator';
	public static TENANTMANAGEMENT_UPDATEBROKER = 'UpdateTenantBroker';

	// NOMENCLATURES
	public static NOMENCLATURES_COUNTRIES = 'GetCountries';
	public static NOMENCLATURES_TIMEZONES = 'GetTimeZones';
	public static NOMENCLATURES_SERVERNAMES = 'GetClusters';
	public static NOMENCLATURES_GENDERS = 'GetGenders';
	public static NOMENCLATURES_LANGUAGES = 'GetLanguages';
	public static NOMENCLATURES_CURRENCY = 'GetCurrencies';

	// REPORTS
	public static REPORTS_GETTYPES = 'GetReportTypes';
	public static REPORTS_GETOPERATORS = 'GetOperators';
	public static REPORTS_GENERATEANDDOWNLOAD = 'GenerateAndDownloadReportDocument';

	// GROUP-RIGHTS
	public static GET_ACTIONS = 'Actions';

	// FINANCIAL
	public static FINANCIAL_GETBILLINGCYCLES = 'billingcycles';
	public static FINANCIAL_GETDAILYBILLINGCYCLES = 'dailybillingcycles';
	public static FINANCIAL_GETDAILYBILLINGCYCLES_BYINVOICE = 'invoices/billingCycles';
	public static FINANCIAL_GENERATEINVOICE = 'generateinvoice';
	public static FINANCIAL_GETINVOICES = 'invoices';
	public static FINANCIAL_UPDATEINVOICE = 'updateinvoice';
	public static FINANCIAL_DELETEINVOICE = 'deleteinvoice';
	public static FINANCIAL_DELETEINVOICEWITHADJUSTMENT = 'DeleteWithAdjustment';
	public static FINANCIAL_CREATEINVOICE = 'createinvoice';
	public static FINANCIAL_GETCSVFORSFM = 'csv';
	public static FINANCIAL_CREDITNOTES_GET = 'creditnotes';
	public static FINANCIAL_CREDITNOTES_CREATE = 'creditnotes/add';
	public static FINANCIAL_CREDITNOTES_UPDATE = 'creditnotes/update';
	public static FINANCIAL_CREDITNOTES_CSVFORSFM = 'creditnotes/csv';
	public static FINANCIAL_CREDITNOTES_DELETE = 'creditnotes/delete';

	public static FINANCIAL_GETBANKS = '';
	public static FINANCIAL_GETBANKACCOUNTS = '';
	public static FINANCIAL_GETBANKACCOUNTBYID = '';
	public static FINANCIAL_CREATEBANKACCOUNT = 'Create';
	public static FINANCIAL_UPDATEBANKACCOUNT = 'Update';

	public static FINANCIAL_GETBANKSTATEMENTS = '';
	public static FINANCIAL_GETBANKSTATEMENTBYID = '';
	public static FINANCIAL_CREATEBANKSTATEMENT = 'Create';
	public static FINANCIAL_UPLOADBANKSTATEMENT = 'Upload';
	public static FINANCIAL_UPDATEBANKSTATEMENT = 'Update';
	public static FINANCIAL_GETBANKSTATEMENT_ALLOCATIONSCSV = 'AllocationCsv';
	public static FINANCIAL_GETBANKSTATEMENT_RECEIPTSANDNOMINALSCSV = 'NominalReceiptCsv';

	public static FINANCIAL_GETBANKSTATEMENTTRANSACTIONS = 'Transactions';
	public static FINANCIAL_GETBANKSTATEMENTTRANSACTIONBYID = '';
	public static FINANCIAL_GETBANKSTATEMENTTRANSACTION_BYINVOICE = 'Allocations';
	public static FINANCIAL_ADDBANKSTATEMENTTRANSACTIONS = 'AddTransactions';
	public static FINANCIAL_UPDATEBANKSTATEMENTTRANSACTION = 'UpdateTransaction';
	public static FINANCIAL_PREPAREALLOCATIONS = 'Allocate';
	public static FINANCIAL_PREPARENOMINALS = 'Nominals';
	public static FINANCIAL_COMPLETEBANKSTATEMENTTRANSACTIONS = 'CompleteBankTransactions';

	public static FINANCIAL_GETBANKNOMINALMAPPINGS = '';
	public static FINANCIAL_CREATEBANKNOMINALMAPPING = 'Create';
	public static FINANCIAL_UPDATEBANKNOMINALMAPPING = 'Update';
	public static FINANCIAL_DELETEBANKNOMINALMAPPING = 'Delete';

	// COMPANIES (BILLING ENTITIES)
	public static COMPANIES_GET = '';

	// LOOKUPS
	public static GETTRANSACTIONTYPES = 'GetTransactionTypes';

	// WINNING PARTICIPATIONS
	public static UPDATE_WINNING_PARTICIPATION_AUTHORISATION_STATUS = 'UpdateWinningParticipationAuthorisationStatus';

	// DRAWS
	public static DRAW_GETBYID = 'GetDrawByID';
	public static DRAW_GETPREVIOUSDRAWS = 'GetPreviousDraws';
	public static DRAW_GETPREVIOUSDRAWSFORGAME = 'GetPreviousDrawsForGame';
	public static DRAW_GETPASTDRAWFORTENANTS = 'GetPastDrawForTenants';
	public static DRAW_GETPASTTENANTDRAWS = 'GetPastTenantDraws'; // used for export
	public static DRAW_GETUPCOMINGDRAWS = 'GetUpcomingDraws';
	public static DRAW_GETUPCOMINGDRAWSFORGAME = 'GetUpcomingDrawsForGame';
	public static DRAW_GETFUTUREDRAWFORTENANTS = 'GetFutureDrawForTenants';
	public static DRAW_GETFUTURETENANTDRAWS = 'GetFutureTenantDraws'; // used for export

	public static DRAW_GETWINNINGPLAYERS = 'GetDrawWinningPlayers';
	public static DRAW_CANCELDRAW = 'CancelDraw';
	public static DRAW_CHANGEDRAWDATE = 'ChangeDrawDate';
	public static DRAW_INSERTMANUALRESULT = 'ManualInsertResults';

	// TICKET MANAGEMENT
	public static TICKET_MANAGEMENT_CREATE_BATCH = 'CreateBatch';
	public static TICKET_MANAGEMENT_CREATE_BATCH_GROUP = 'CreateBatchGroup';
	public static TICKET_MANAGEMENT_GET_BATCH_GROUPS = 'GetBatchGroups';
	public static TICKET_MANAGEMENT_GET_DISTRIBUTED_BATCHES = 'GetDistributedBatches';
	public static TICKET_MANAGEMENT_GET_BATCHES_BY_OPERATOR = 'GetBatchesByOperator';
	public static TICKET_MANAGEMENT_TOGGLE_ACTIVE_BATCH = 'ActivateBatch';
	public static SUBSCRIPTIONS_CANCEL = 'Cancel';
	public static SUBSCRIPTIONS_GET = '';
	public static SUBSCRIPTIONS_GET_MODELS = '';

	// RETAIL AGENTS
	public static AGENTS_RESET_PASSWORD = 'ResetPassword';
	public static AGENTS_TOPUP_BALANCE = 'TopUpBalance';
	public static AGENTS_ADJUST_BALANCE = 'AdjustBalance';
	public static AGENTS_ADJUST_BALANCE_DEFAULT_ALL = 'AdjustBalanceToDefaultAll';
	public static AGENTS_TOPUP_HISTORY = 'gettopuphistory';
	public static AGENTS_OPERATOR_CURRENCIES = 'getoperatorcurrencies';

	public static AGENTS_RECONCILIATION = 'GetRetailAgentReconciliation';
	public static AGENTS_RECONCILIATION_HISTORY = 'GetRetailAgentReconciliationHistory';
	public static AGENTS_RECONCILIATION_SALES = 'GetRetailAgentReconciliationSalesStatistics';
	public static AGENTS_RECONCILIATION_BY_DATE = 'GetRetailAgentReconciliationByDate';
	public static AGENTS_POST_RECONCILIATION_HISTORY = 'PostReconciliationHistory';
	public static AGENTS_GET_RECONCILIATION_TOTALS = 'GetRetailAgentReconciliationTotals';
	public static AGENTS_GET_RECONCILIATION_BY_ID = 'GetRetailAgentReconciliationByID';

}
