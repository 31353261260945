import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Left in this folder and not in HelioCoreServices as this service is only used in
 * the secure-content component and not app wide
 */
@Injectable()
export class AppLayoutService {
	menuClick = false;
	menuButtonClick = false;
	isMenuStatic = true;
	isMenuHorizontal = false;

	menuActiveSubject: Subject<boolean> = new Subject<boolean>();
	menuClickSubject: Subject<boolean> = new Subject<boolean>();
	toggleMenuLayoutClick: Subject<boolean> = new Subject<boolean>();
	sidebarActiveChange: Subject<boolean> = new Subject<boolean>();

	constructor() {
		this.setMenuStatic();
	}

	isMobile() {
		return window.innerWidth <= 1024;
	}

	toggleMenuLayout() {
		this.isMenuStatic = !this.isMenuStatic;
		this.saveMenuLayout();
		this.toggleMenuLayoutClick.next(this.isMenuStatic);
	}

	sidebarActive(isSidebarActive: boolean) {
		this.sidebarActiveChange.next(isSidebarActive);
	}

	private saveMenuLayout() {
		localStorage.setItem('is_menu_static', this.isMenuStatic.toString());
	}

	private setMenuStatic() {
		if (this.isMobile()) {
			this.isMenuStatic = false;
		} else {
			const isMenuStaticLocalStorage = localStorage.getItem('is_menu_static') === null || localStorage.getItem('is_menu_static') === 'true';
			if (isMenuStaticLocalStorage) {
				this.isMenuStatic = true;
			} else {
				this.isMenuStatic = false;
			}
		}
	}
}
