import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NavigationItem } from './navigation-item.model';
import { AppLayoutService } from '../layout/secure/app-layout.service';
import { AppConfigService, LoginService } from '../helio-core-services';

@Component({
	selector: 'he-navigation',
	styleUrls: [
		'./navigation.component.scss'
	],
	templateUrl: './navigation.component.html',
	animations: [
		trigger('fadeLogo', [
			state('false', style({ opacity: 0 })),
			state('true', style({ opacity: 1 })),
			transition('1 <=> 0', animate('250ms'))
		])
	]
})

export class NavigationComponent implements OnInit {
	activeNavItem: NavigationItem;
	menuItems: any[];
	reset = false;
	sidebarActive = true;
	isMenuStatic = true;

	private userName = '';

	constructor(
		private router: Router,
		private loginService: LoginService,
		private appLayoutService: AppLayoutService,
		private appConfigService: AppConfigService
	) { }

	ngOnInit() {
		this.userName = localStorage.getItem('user_name');
		this.setMenuItems();

		this.appLayoutService.toggleMenuLayoutClick.subscribe(isMenuStatic => {
			this.isMenuStatic = isMenuStatic;
		});
		this.isMenuStatic = this.appLayoutService.isMenuStatic;
		this.sidebarActive = this.appLayoutService.isMenuStatic;
	}

	setMenuItems() {
		this.menuItems = [
			{
				label: 'User Management', icon: 'person', routeName: 'user-management', items: [
					{ label: 'Users', icon: 'person', routeName: 'user-management', routerLink: ['/user-management/users'] },
					{ label: 'Groups', icon: 'people', routeName: 'group-management', routerLink: ['/group-management/groups'] }
				]
			},
			{
				label: 'Tenant Management', icon: 'work', routeName: 'tenant-management', routerLink: ['/tenant-management']
			},
			{
				label: 'Player Management', icon: 'account_circle', routeName: 'player-management', items: [
					{ label: 'Players', icon: 'account_circle', routeName: 'player-management/players', routerLink: ['/player-management/players'] },
					// wallet player management
					{ label: 'Players', icon: 'account_circle', routeName: 'wallet/wallet-players', routerLink: ['/wallet/wallet-players'] },
					{ label: 'KYC', icon: 'folder_shared', routeName: 'player-management/kyc', routerLink: ['/player-management/kyc'] },
					{
						label: 'Winning Participations', icon: 'local_play', routeName: 'player-management/winning-participations',
						routerLink: ['/player-management/winning-participations']
					}
				]
			},
			{
				label: 'Ticket Management', icon: 'local_offer', routeName: 'ticket-management', items: [
					{
						label: 'Issue Batch', icon: 'queue',
						routeName: 'ticket-management/issue-batch', routerLink: ['/ticket-management/issue-batch']
					},
					{
						label: 'Distributed Batches', icon: 'donut_small',
						routeName: 'ticket-management/distributed-batches', routerLink: ['/ticket-management/distributed-batches']
					},
					{
						label: 'Subscriptions', icon: 'book',
						routeName: 'ticket-management/subscriptions', routerLink: ['/ticket-management/subscriptions']
					}
				]
			},
			{
				label: 'Draw Processing', icon: 'gradient', routeName: 'draw-processing', items: [
					// {
					// 	label: 'Current Draws', icon: 'gradient',
					// 	routeName: 'draw-processing/current-draws', routerLink: ['/draw-processing/current-draws']
					// },
					{
						label: 'Upcoming Draws', icon: 'hdr_strong',
						routeName: 'draw-processing/upcoming-draws', routerLink: ['/draw-processing/upcoming-draws']
					},
					{
						label: 'Previous Draws', icon: 'hdr_weak',
						routeName: 'draw-processing/previous-draws', routerLink: ['/draw-processing/previous-draws']
					}
				]
			},
			{
				label: 'Wallet Transactions', icon: 'account_balance_wallet', routeName: 'wallet', routerLink: ['/wallet']
			},
			{
				label: 'Reports', icon: 'show_chart', routeName: 'reports', routerLink: ['/reports']
			},
			{
				label: 'Finance', icon: 'account_balance_wallet', routeName: 'financial', items: [
					{
						label: 'Billing Aggregates', icon: 'account_balance_wallet',
						routeName: 'financial/daily-aggregates', routerLink: ['/financial/daily-aggregates']
					},
					{ label: 'Billing Invoices', icon: 'receipt', routeName: 'financial/billing-invoices', routerLink: ['/financial/billing-invoices'] },
					{ label: 'Bank Accounts', icon: 'account_balance', routeName: 'financial/bank-accounts', routerLink: ['/financial/bank-accounts'] },
					{
						label: 'Nominal Mappings', icon: 'compare_arrows',
						routeName: 'financial/bank-nominal-mappings', routerLink: ['/financial/bank-nominal-mappings']
					}
				]
			},
			{
				label: 'Retail Management', icon: 'contacts', routeName: 'retail-agents', items: [
					{ label: 'Find Ticket', icon: 'search', routeName: 'retail-agents/find-ticket', routerLink: ['/retail-agents/find-ticket'] },
					{ label: 'Agents', icon: 'recent_actors', routeName: 'retail-agents/agents', routerLink: ['/retail-agents/agents'] },
					{
						label: 'Reconciliation', icon: 'playlist_add_check',
						routeName: 'retail-agents/reconciliation', routerLink: ['/retail-agents/reconciliation']
					}
				]
			}
		];

		// renaming player menu items if user has rights to both player management pages
		// remove when wallet functionality is removed!
		if (this.checkIfHasWalletPageRights('wallet/wallet-players') && this.checkIfHasWalletPageRights('player-management/players')) {
			this.menuItems[2].items[0].label = 'Engine Players';
			this.menuItems[2].items[1].label = 'Wallet Players';
		}
	}

	onSidebarClick(event: Event) {
		this.appLayoutService.menuClick = true;
	}

	activateNavItem(navItem: NavigationItem) {
		this.userName = localStorage.getItem('user_name');
		// set active to false for the current activateNavItem
		if (this.activeNavItem === undefined) {
			this.activeNavItem = navItem;
		} else {
			this.activeNavItem.isActive = false;
		}

		navItem.isActive = true;
		this.activeNavItem = navItem;
	}

	toggleMenu() {
		this.appLayoutService.toggleMenuLayout();
	}

	mouseOverLeaveHandler(isActive: boolean) {
		this.sidebarActive = this.appLayoutService.isMobile() ? true : isActive;
		this.appLayoutService.sidebarActive(this.sidebarActive);
	}

	// remove when wallet functionality is removed!
	private checkIfHasWalletPageRights(url: string) {
		const urlList = url.split('/');
		const localStorageList = JSON.parse(JSON.parse(localStorage.getItem('access_rights')).Pages);
		let hasRights = true;

		let route;
		for (let i = 0; i < urlList.length; i++) {
			if (urlList[i] !== '') {
				const tempURL = urlList[i];
				const pagePermissions: any[] = (i === 0) ? localStorageList : route.ChildPages;

				route = pagePermissions.find(tempPageObj => {
					return tempPageObj.Name === tempURL;
				});

				if (route === undefined) {
					hasRights = false;
					break;
				}
			}
		}

		return hasRights;
	}
}
