import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { ServiceController } from '../../shared/utilities/service-utilities/service-controller.urls';
import { ServiceAction } from '../../shared/utilities/service-utilities/service-action.urls';
var CompanyService = /** @class */ (function (_super) {
    tslib_1.__extends(CompanyService, _super);
    function CompanyService(http, appConfigService) {
        var _this = _super.call(this, http, ServiceController.COMPANY_CONTROLLER, appConfigService.serviceBaseURL) || this;
        _this.http = http;
        _this.appConfigService = appConfigService;
        return _this;
    }
    CompanyService.prototype.getCompanies = function (searchParams) {
        return this.get(ServiceAction.COMPANIES_GET, undefined, searchParams);
    };
    return CompanyService;
}(BaseService));
export { CompanyService };
