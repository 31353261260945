import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppConfigService } from './app-config.service';

import { ServiceController, ServiceAction } from '../../shared/utilities/service-utilities';
import { ChangePassword, User } from '../../shared/models';
import { DataTableServerResponse } from '../../shared/components/data-table-v3';

/**
 * Service class used to handle HTTP requests related to Login
 */
@Injectable()
export class UsersService extends BaseService {

	public totalRowCount: number;

	// private accessToken = localStorage.getItem('access_token');

	/**
	 * Constructor is setting the headersConfig with isAnonymous true and contentType 'application/x-www-form-urlencoded'
	 * @param http Used to pass the Angular Http client which is responsible to making HTTP requests
	 */
	constructor(protected http: HttpClient, private appConfigService: AppConfigService) {
		super(http, ServiceController.USERS_CONTROLLER, appConfigService.serviceBaseURL);
	}

	getUsers(searchParams?: HttpParams): Observable<DataTableServerResponse> {
		return this.get<DataTableServerResponse>(undefined, undefined, searchParams);
	}

	getUser(userID: number): Observable<User> {
		return this.get<User>(undefined, [userID]);
	}

	createUser(user: User) {
		return this.post(undefined, user);
	}

	editUser(user: User) {
		return this.edit(undefined, user);
	}

	deleteUser(id: number) {
		return this.delete(ServiceAction.GENERIC_DELETE, [id]);
	}

	changePassword(data: ChangePassword) {
		return this.edit(ServiceAction.USER_CHANGE_PASSWORD, data);
	}

	resetPassword(userID: number) {
		return this.edit(ServiceAction.USER_RESET_PASSWORD, userID);
	}
}
