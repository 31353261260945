import { OnInit } from '@angular/core';
var AppSubMenuComponent = /** @class */ (function () {
    // constructor(public app: AppComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) { }
    function AppSubMenuComponent(router, location, appLayoutService) {
        this.router = router;
        this.location = location;
        this.appLayoutService = appLayoutService;
        this.isHorizontal = false;
    }
    AppSubMenuComponent.prototype.ngOnInit = function () {
        this.isHorizontal = this.appLayoutService.isMenuHorizontal;
    };
    AppSubMenuComponent.prototype.itemClick = function (event, item, index) {
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items || item.command || item.url) {
            this.activeIndex = (this.activeIndex === index) ? null : index;
        }
        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            // setTimeout(() => {
            // 	this.appMenu.layoutMenuScrollerViewChild.moveBar();
            // }, 450);
            event.preventDefault();
        }
        // hide menu
        if (!item.items) {
            if (this.appLayoutService.isMobile()) {
                // this.appLayoutService.sidebarActive = false;
                this.appLayoutService.menuActiveSubject.next(false);
            }
        }
    };
    AppSubMenuComponent.prototype.isActive = function (index) {
        return this.activeIndex === index;
    };
    Object.defineProperty(AppSubMenuComponent.prototype, "reset", {
        get: function () {
            return this._reset;
        },
        set: function (val) {
            this._reset = val;
        },
        enumerable: true,
        configurable: true
    });
    return AppSubMenuComponent;
}());
export { AppSubMenuComponent };
