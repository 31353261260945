import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpinnerModule } from 'primeng/spinner';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

import {
	ChangeStatusDialogComponent, HeaderComponent,
	FormMessagesComponent, AdvancedSearchComponent, AdvancedSearchFieldComponent,
	AdvancedSearchFieldHostDirective, DataTableV3Component, ColumnDataComponent,
	ColumnDataPipe
} from './components';

import { CharsDirective } from './directives';
import { CountdownToPipe } from './pipes';
import { TogglePasswordVisibilityDirective } from './directives/passwordReveal.directive';

@NgModule({
	declarations: [
		/* ==== Data Table ==== */
		DataTableV3Component,
		ColumnDataComponent,
		ColumnDataPipe,
		AdvancedSearchComponent,
		AdvancedSearchFieldComponent,
		AdvancedSearchFieldHostDirective,
		/* ==== Data Table ==== */

		ChangeStatusDialogComponent,
		FormMessagesComponent,
		HeaderComponent,

		CharsDirective,
		CountdownToPipe,
		TogglePasswordVisibilityDirective
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,

		/* ==== PrimeNG ==== */
		ButtonModule,
		CalendarModule,
		ConfirmDialogModule,
		DialogModule,
		DropdownModule,
		InputTextModule,
		KeyFilterModule,
		MenuModule,
		MessagesModule,
		MultiSelectModule,
		SelectButtonModule,
		SliderModule,
		SpinnerModule,
		TableModule,
		TooltipModule,
		TriStateCheckboxModule
		/* ==== PrimeNG ==== */
	],
	exports: [
		/* ==== New Data Table ==== */
		DataTableV3Component,
		AdvancedSearchComponent,
		/* ==== New Data Table ==== */

		ChangeStatusDialogComponent,
		FormMessagesComponent,
		HeaderComponent,

		CharsDirective,
		TogglePasswordVisibilityDirective,
		CountdownToPipe
	],
	entryComponents: [
		AdvancedSearchFieldComponent
	]
})

export class SharedModule { }
